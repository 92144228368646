<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">用户协议</div>
      <div class="title1">
        您同意在我们的网站上注册账户或以其他方式使用我们的服务，即表示您同意以下条款和条件(“条款”)。如果您不同意这些条款，请勿注册账户或以其他方式使用我们的服务。
      </div>
      <div class="title1">
        请注意，您对服务的使用也受我们隐私政策的约束。本公司保留随时更新条款和隐私政策的权利，恕不另行通知。
      </div>

      <div class="con">
        <h3> 1.定义</h3> <br>
        账户: 用户在平台上为访问服务而创建的一个或多个账户。 <br>
        平台: 公司和/或其附属机构发布的平台，允许用户与其他用户交换通证。该平台不允许用户将通证交换为法定货币。<br>
        服务: 通过网站、附属网站、应用程序和平台提供的任何服务。<br>
        网站: 518csgo，网址为: https://518csgo.com/<br>
        用户: 任何服务的用户(此处对“您”或“您的”的所有引用均指每个用户)。<br>
        <h3>2 .资格、限制区域和允许用途</h3><br>
        资格。 通过访问服务，您需保证:<br>
        ( 1 )年龄至少为18岁，或至少为适用法律规定的最低年龄，以便我们无需经过您父母同意即可合法向您提供服务；<br>
        ( 2 )加入这些条款(并且在法律上有能力这样做)；<br>
        ( 4 )所在国家适用法律不禁止访问和使用服务；<br>
        ( 5 ) 未有过因违法操作，被暂停交易或者调离交易现场的记录；<br>
        ( 6 )拥有您在平台上使用的所有通证(或您能够与之进行授权交易的通证)；<br>
        ( 7 )拥有全部必要的同意和授权，以执行从您的账户发起的全部交易；<br>
        ( 8 )提供真实、准确、完整的开户资料；<br>
        ( 9 )已审阅本条款所载的风险披露声明，并同意承担其中所述的所有风险。<br>
        允许使用。 您同意在访问服务时遵守所有适用的法律和法规。您同意不将服务用于任何非法活动，包括但不限于非法赌博、洗钱、欺诈、敲诈、勒索、数据交换、资助恐怖主义或其他暴力活动。您还同意，您不会对平台、站点和应用程序进行黑客、反编译、反向工程、反汇编、尝试获取源代码、解密或以其他方式损害其完整性和安全性。<br>
        <h3>3 .账户</h3><br>
        您必须创建账户才能访问和使用服务，并且必须提供有效的电子邮件地址才能创建账户。您必须在创建账户时提供完整、准确和最新的信息，并在发生任何更改时立即更新这些信息。您可以请求公司删除或去除您提供的任何信息；但是，您承认并同意公司可以拒绝您的请求或恢复以前删除和去除的任何信息。您必须对您账户下发生的所有活动负责，并承担未经授权访问的所有风险。您同意维护登录凭证的安全性和机密性，并拒绝在未经授权的情况下(无论是通过站点、应用程序还是其他方式)访问您的账户。如果您发现或怀疑任何未经授权访问或使用您的账户，必须立即通知公司。<br>
        您同意公司：有权限制任何用户或关联公司能够创建的账户数量。<br>
        您同意本公司保留：随时以任何理由终止您的账户的权利和唯一决定权。<br>
        <h3>4 .风险披露表</h3><br>
        (A) 技术<br>
        复杂性。 通证一般用非常技术性的语言描述；需要对应用密码学和计算机科学有全面的了解，才能认识到固有的风险。通过使用本服务，您表示并保证您拥有足够的知识、市场成熟度、经验和/或专业建议，足以对您根据本服务所进行的所有交易的价值和风险进行审慎评估。您同意承担上述评估的全部责任。<br>
        网络攻击。 如果平台、应用程序、站点或服务的任何其他组件遭到网络攻击，您的账户和其中的通证可能会受到不利影响。本公司及其附属公司均不保证其在发生此类攻击时可预见、预防、减轻或采取纠正措施。<br>
        暂停/停止支助/撤出。 如果在平台上交易的特定通证发生分叉或网络攻击，您同意公司可以暂时中止相关通证的服务(无论是否事先通知您)，并且公司可以不支持(或停止支持) 分叉通证。如果可行，公司将为您提供收回此类通证的机会。有关受攻击通证的存款、提款和用户余额的决议将由公司根据具体情况自行决定。本公司不就平台的安全性做出任何陈述或保证，也不对任何价值损失或被盗财产负责，无论本公司是否疏忽提供适当的安全性。<br>
        （B）交易失败；网上存款条款<br>
        平台上的交易可能由于若干原因而失败，包括但不限于价格变化。本公司不对任何交易的正常执行做出任何陈述或保证。本公司特此声明：放弃负责因任何交易失败而产生、或与任何交易失败相关的所有损失和损害。您同意并承认公司并没有责任通知交易失败。 公司可在任何时候拒绝执行交易、施加交易金额的限制或约束，无需事先通知，并可自行决定。具体而言，如果公司怀疑可能存在洗钱、资助恐怖主义、欺诈或任何其他非法行为，或者公司怀疑交易违反了相关服务条款[这些条款是指？](例如，涉及开设账户和随后关闭账户而不进行任何实际交易的交易)，公司保留拒绝处理、取消或撤销任何交易和禁用用户账户的权利。虽然公司可酌情撤销交易，但用户不得更改、撤回或取消其交易授权，但部分填写的订单除外。 本公司可更正、冲销或取消任何因处理交易指示错误及其他原因而影响的交易。如果出现错误，您的补救措施将受到限制。我们不能保证此类取消或退款始终是可能的。 公司的平台运行使得每个用户的订单与其他用户的订单相匹配。根据下订单时的交易活动，可以部分填写订单或填写多个订单。 您确认公司为每个账户提供加密通证存储功能，以便进行交易。<br>
        （C）中止<br>
        本公司有权随时终止先前提供的通证可用性。在这种情况下，公司将为您提供收回这些通证的机会。<br>
        <h3>5 .许可限制</h3><br>
        本公司授予您有限、非排他性、不可转让的许可，允许您访问和使用本服务(无论是通过应用程序还是网站)，仅用于本服务的预期功能以及本公司明确允许的其他批准用途。明确禁止任何其他使用服务的行为。<br>
        使用应用程序时，您同意不会去:<br>
        ( A )反编译、反向工程、反汇编、尝试导出应用程序的源代码或解密应用程序；<br>
        ( B )对应用程序进行任何修改、改编、改进、增强、翻译或衍生作品；<br>
        ( C )违反与您访问或使用应用程序相关的任何适用法律、规则或法规；<br>
        ( E )将该应用程序用于并非设计或意图用于的任何其他目的；<br>
        ( F )通过网络提供应用程序，或以其他方式允许多个移动设备或用户同时访问或使用该应用程序；<br>
        ( G )使用应用程序向任何网站发送自动查询或发送任何未经请求的商业电子邮件；<br>
        ( H )未经授权使用公司或其关联公司的专有信息或知识产权(包括但不限于商标)。<br>
        <h3>6 .用户行为守则</h3><br>
        使用或访问服务时，您同意不会:<br>
        ( A )通过密码挖掘或其他方式获取或企图获取对网站、其他用户账户或服务任何其他组成部分的未经授权访问；<br>
        ( B )在网站或服务的任何组成部分上使用或企图使用网络爬虫；<br>
        ( C )对我们的基础设施造成巨大负担；<br>
        ( D )将含有计算机病毒、特洛伊木马或蠕虫的任何材料上载到网站。<br>
        <h3>7 .隐私政策相关</h3><br>
        你的隐私对我们很重要。但是，我们可能被迫根据相应的法律、规则或法规共享您的信息。有关详细信息，请参阅我们的《隐私政策》。<br>
        <h3>8 .反洗钱和验证用户身份政策</h3><br>
        公司执行银行层面的(“验证用户身份”)流程，以遵守反洗钱( AML )法律。该公司对照政府观察名单对用户数据进行交叉核对。如果用户或用户的交易被标记为可疑，公司将要求用户提供额外的身份证明，并可暂停该用户的任何交易、存款或提款，直至收到令公司满意的身份证明。<br>
        公司对用户验证级别使用内部政策。本公司有权利为任何用户确定适当验证级别和标准的，以及在不事先通知的情况下降低用户级别的权利。公司可随时执行措施，根据国籍、居住国或任何其他因素限制核查水平和标准。<br>
        您同意，如果无法达到所需的验证级别，则可能无法存放或提取通证。您同意独自承担责任，不要求公司或其关联公司对此类损失承担责任。<br>
        您特此授权公司直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并/或防止欺诈行为的发生，包括查询公开报告中包含的身份信息(例如您的姓名、地址、过去地址或出生日期)，查询与您关联的银行账户相关的账户信息(例如姓名或账户余额)，并根据此类查询和报告的结果采取我们认为合理且必要的行动。您还授权可能收到此类查询或请求的任何和所有第三方对此类查询或请求做出全面响应。<br>
        <h3>9 .不可转让性</h3><br>
        虽然向用户提供的账户和服务不可转让，但公司有权将账户和服务的所有权、利益进行义务转让、转让或出售给任何人，这些条款对公司或其贷款人的继承人和受让人(如有)继续有效。<br>
        <h3>10 .永久性撤回服务</h3><br>
        公司有权暂停或终止您对服务的访问。以及如果公司根据适用的法律、规则或法规，怀疑您或其他人使用您的账户从事非法活动，则可以停用或取消您的账户。在这种情况下，除非另有相应的法律禁止，否则您可以在账户停用或取消后 60天内转让与您的账户相关的通证。如果您的账户在被取消或暂停时，任何交易处于挂起状态，则可根据需要取消或退还此类交易。<br>
        通证返还流程：公司注销您的账户——您授权公司取消或暂停挂起交易——公司通过服务或应用程序向您发出通知——与交易相关联的通证会返回公司为您保留的钱包地址。<br>
        如果本公司无法通过合理的商业努力将您的通证归还给CoinFit为您存档的钱包地址，本公司可根据香港无人认领财产法向适用的政府机构报告和汇寄通证。<br>
        <h3>11 .免责声明与免责事项</h3><br>
        用户了解并同意，在任何情况下，我们不就以下各事项承担责任：<br>
        (1)收入的损失；<br>
        (2)交易利润或合同损失；<br>
        (3)业务中断；<br>
        (4)预期可节省的货币的损失；<br>
        (5)信息的损失；<br>
        (6)机会、商誉或声誉的损失；<br>
        (7)数据的损坏或损失；<br>
        (8)购买替代产品或服务的成本；<br>
        (9)数字资产消亡或退出市场；<br>
        (10)任何由于侵权（包括过失）、违约或其他任何原因产生的间接的、特殊的或附带性的损失或损害，不论这种损失或损害是否可以为我们合理预见；不论我们是否事先被告知存在此种损失或损害的可能性。<br>
        （1）条至（10）条均是彼此独立的。<br>
        用户了解并同意，我们不对因下述任一情况而导致用户的任何损害赔偿承担责任：<br>
        (1)我们有合理理由认为用户的具体交易事项可能存在重大违法或违约情形。<br>
        (2) 我们有合理的理由认为用户在我们的行为涉嫌违法或不当。<br>
        (3) 通过我们服务购买或获取任何数据、信息或进行交易等行为或替代行为产生的费用及损失。<br>
        (4)用户对本网站服务的误解。<br>
        (5)非因我们的原因而造成用户信息被泄露、毁损或灭失。<br>
        (6)任何非因我们的原因而引起的与我们提供的服务有关的其它损失。<br>
        (7)我们不能保证我们包含的全部信息、程序、文本等完全安全，不受任何病毒、木马等恶意程序的干扰和破坏，故用户登陆、使用我们任何服务或下载及使用该下载的任何程序、信息、数据等均是用户个人的决定并自行承担风险及可能产生的损失。<br>
        (8)我们对我们链接的任何第三方网站的任何信息、产品及业务及其他任何形式的不属于我们的主体的内容等不做任何保证和承诺，用户如果使用第三方网站提供的任何服务、信息及产品等均为用户个人决定且承担由此产生的一切责任。<br>
        (9)我们对于用户使用我们服务不做任何明示或暗示的保证，包括但不限于我们提供服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，我们也不对我们提供的服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。是否登陆或使用我们提供的服务是用户个人的决定且自行承担风险及可能产生的损失。我们对于数字资产的市场、价值及价格等不做任何明示或暗示的保证，用户理解并了解数字资产市场是不稳定的，价格和价值随时会大幅波动或崩盘，交易数字资产是用户个人的自由选择及决定且自行承担风险及可能产生的损失。<br>
        (10)协议中规定的我们的保证和承诺是由我们就本协议和我们提供的服务的唯一保证和陈述，并取代任何其他途径和方式产生的保证和承诺，无论是书面的或口头的，​​明示的或暗示的。所有这些保证和陈述仅仅代表我们自身的承诺和保证，并不保证任何第三方遵守本协议中的保证和承诺。<br>
        (11)我们并不放弃本协议中未提及的在法律适用的最大范围内我们享有的限制、免除或抵销我们损害赔偿责任的任何权利。<br>
        <h3>12 .适用法律和争议解决</h3><br>
        本使用条款应受香港法律管辖，并应在所有方面解释为香港合同。由本使用条款引起或与本使用条款相关的任何索赔或诉讼(包括侵权索赔)应受香港法律管辖，并根据香港法律进行解释和执行。<br>
        为加快并控制争议成本，您同意，您使用服务或本条款、或您使用服务或本条款相关的任何法律引起的索赔，包括本条款的形成、有效性、可执行性、范围或适用性(以下简称“索赔”)将按以下方式解决:除了禁令或衡平法上的救济索赔或知识产权索赔(可在任何主管法院提起而无需出具保函)之外，您使用服务引起的任何争议应根据香港国际仲裁中心(以下简称“仲裁中心”)通过保密、有约束力的仲裁最终解决。这意味着，除知识产权诉讼之外的所有索赔，如版权或商标侵权诉讼，或寻求非金钱救济的索赔，都将受到具有约束力的仲裁。仲裁应以英文在香港进行，仲裁决定可在任何法院执行。<br>
        要开始仲裁程序，您必须通过邮件发送一封请求仲裁的信函，并向我们描述您的索赔要求.<br>
        <h3>13 .没有集体诉讼</h3><br>
        您和公司只能以您或其个人身份对另一方提出索赔，而不能在任何声称的类别、代表、合并或多索赔人诉讼中作为原告或类别成员提出索赔。<br>
        你和公司都放弃了陪审团审判的权利。<br>
        <h3>14 .可分性；弃权</h3><br>
        如果本条款中的任何条款被视为非法、无效或由于任何原因无法执行，则该条款应被视为可与本条款分离，且不会影响任何剩余条款的有效性和执行性。本公司延迟或未能执行其任何权利不应构成对该等权利的持续放弃。<br>
        <h3>15 .整合</h3><br>
        本条款以及公司发布的任何政策或运营规则构成您与公司之间的完整协议和了解，并取代您与公司之间以前或同时期的任何形式的协议、沟通和建议(包括但不限于本条款的任何以前版本)。<br>
        <h3>16 .不可抗力因素</h3><br>
        除上述适用免责声明外，如果由于超出公司合理控制范围的原因(包括但不限于天灾、任何政府行为、战争或其他敌对行为、内乱、火灾、水灾、暴风雪、地震、爆炸、禁运、恐怖主义行为、停电、设备故障、劳资纠纷或争议、任何第三方数据提供商或其他第三方信息提供商的行为、第三方软件或通信方法中断)而导致中断和/或延迟，公司应免除履行本条款。<br>
        <h3>17 .修正</h3><br>
        公司可随时修改、删除或以其他方式修改本使用条款和隐私政策中的任何条款；前提是，公司至少在修改前七天，通过您在公司存档的电子邮件向您发出通知。<br>
        <h3>18 .暂停和终止服务</h3><br>
        公司可出于任何原因，在事先通知或不事先通知您的情况下，暂停和终止服务。您承认并同意，如果市场状况不稳定、不合理，公司可在事先通知或不通知您的情况下，通过启动交易限制或其他适当的措施，终止服务。<br>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    margin-top: 46px;
    margin-bottom: 88px;
    font-size: 18px;
    color: #848492;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
  .title1 {
    color: #c3c3e2;
    font-size: 20px;
    padding-bottom: 10px;
  }
}
</style>