<template>
  <div class="home">
    <div style="height: 62px">
      <div class="top">
        <el-row class="top-el-row">
          <!-- <div class="top-btn" @click="showMenu" v-if="showMenuState">
          <img src="../assets/img/menu1.png" />
        </div> -->
          <div class="top-l-r">
            <el-col :span="16" class="logo logo1">
              <img
                style="height: 40px"
                src="../assets/img/menu2.png"
                @click="showMenu"
                class="yidong-menu"
              />
              <img
                @click="gotoNav"
                class="imlogo-spang1 logo-span"
                src="../assets/img/logo33.jpg"
                style="height: 40px !important"
              />
              <span class="logo-word">SWANSBOX</span>
              <!-- <img class="zm" src="../assets/img/1.png" /> -->
              <!-- <span class="span-line logo-span"></span> -->

              <!-- <div class="nav2" v-if="showNav" v-clickoutside="clickOut">
                <ul>
                  <li
                    v-for="(item, index) in menu"
                    :key="index"
                    @click="goMenu(item.id, item.path)"
                  >
                    <img
                      :class="item.selState ? '' : 'img1'"
                      v-if="item.selState"
                      :src="item.img1"
                    />
                    <img class="img2" v-if="!item.selState" :src="item.img2" />
                    <img
                      :class="item.selState ? 'img4' : 'img3'"
                      :src="item.img1"
                    />
                    <div :class="item.selState ? 'item-val' : ''">
                      {{ item.value }}
                    </div>
                  </li>
                </ul>
              </div> -->
              <BaseHeader v-if="showNav" @goMenu="goMenu" />
            </el-col>
            <el-col :span="8" class="top-name">
              <!-- <div class="top-con"> -->
              <!-- <span>{{ name }}</span> -->
              <!-- </div> -->
              <div class="top-right" v-if="loginfalse">
                <div class="btn" @click="showReg">注册</div>
                <div class="btn btn1" @click="showLogin">登录</div>
              </div>
              <div class="top-right1" v-if="!loginfalse" @click="drawerFun">
                <!-- <div class="mess">
                  <div class="mess-left">
                    <img :src="me.img" />
                    <span class="mess-span1">{{ me.name }}</span>
                  </div>
                  <div class="mess-right">
                    <span class="mess-span2">
                      <p>
                        <img src="../assets/img/money.png" />
                        <strong>{{ Number(money).toFixed(2) }}</strong>
                      </p>
                      <p>
                        <img src="../assets/img/masonry.png" />
                        <strong>{{ Number(masonry).toFixed(2) }}</strong>
                      </p>
                    </span>
                  </div>
                </div> -->
                <div class="mess">
                  <div class="mess-right" title="查看->个人中心">
                    <span class="mess-span1">{{ me.name }}</span>
                    <span class="mess-span2">
                      <img src="../assets/img/money.png" />
                      <strong>{{ Number(money).toFixed(2) }}</strong>
                      <img
                        src="../assets/img/masonry.png"
                        style="margin-left: 5px; height: 12px"
                      />
                      <strong>{{ Number(masonry).toFixed(2) }}</strong>
                    </span>
                  </div>
                  <div class="mess-left">
                    <img :src="me.img" />
                  </div>
                </div>
                <!-- <div class="top-right1-ico">
                  <img src="../assets/img/menu.png" />
                </div> -->
              </div>
              <!-- <div class="p-r" :class="loginfalse ? 'isLogin' : ''">
              <div class="top-bag logo-span" @click="gopath('Dota')">
                <img src="../assets/img/top1.png" />
                <span class="span text">饰品背包</span>
              </div>
              <div class="top-pay logo-span" @click="gopath('Payment')">
                <img src="../assets/img/top2.png" />
                <span class="span text">充值</span>
                <span v-if="giveBillie > 0" class="pay-span">+送{{giveBillie}}%</span>
              </div>
            </div> -->
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
    <div class="maringtop75 backColor">
      <div class="bot-right">
        <div v-if="loading" class="el-loading-spinner">
          <i class="el-icon-loading"> </i>
          <p class="el-loading-text">正在加载中...</p>
        </div>
        <router-view
          @backAction="backAction"
          :openHongbao="openHongbao"
          :yidongmenu="yidongmenu"
          :showNav="showNav"
        ></router-view>
      </div>
    </div>

    <!--注册盒子-->
    <div class="reg" v-if="regBox">
      <div class="reg-warp">
        <div class="btn-x" @click="hideReg">X</div>
        <div class="reg-sel">
          <span :class="phoneregBox ? 'span1' : 'span2'" @click="phoneReg"
            >手机注册</span
          >
          <!-- <span :class="emilregBox ? 'span1' : 'span2'" @click="emilReg"
            >邮箱注册</span
          > -->
        </div>
        <div class="reg-hint" v-if="regHint">{{ regHintText }}</div>
        <div class="input" v-if="phoneregBox">
          <el-input
            class="input1"
            v-model="phoneinput1"
            placeholder="手机号码"
          ></el-input>
          <el-input
            class="input1"
            v-model="phoneinput2"
            placeholder="设置密码"
            type="password"
          ></el-input>
          <div class="input1-warp">
            <el-input
              class="input1"
              v-model="phoneinput3"
              placeholder="验证码"
            ></el-input>
            <button
              class="getCode"
              @click="
                getCodeShow = true;
                validateCodeType = 'register';
              "
              :disabled="!phonecodeState"
            >
              <i v-if="loadingReg" class="el-icon-loading"></i>
              {{ phonecodeState ? "获取验证码" : phonecodeTime + " s" }}
            </button>
          </div>
          <el-input
            v-if="inputCode"
            class="input1"
            v-model="phoneinput4"
            placeholder="邀请码（没有可以不填）"
          ></el-input>
        </div>
        <div class="input" v-if="emilregBox">
          <el-input
            class="input1"
            v-model="emilinput1"
            placeholder="邮箱"
          ></el-input>
          <el-input
            class="input1"
            v-model="emilinput2"
            placeholder="设置密码"
            type="password"
          ></el-input>
          <div class="input1-warp">
            <el-input
              class="input1"
              v-model="emilinput3"
              placeholder="验证码"
            ></el-input>
            <span class="getCode" @click="getemilCode">{{
              emilcodeState ? "获取验证码" : emilcodeTime + " s"
            }}</span>
          </div>
          <el-input
            class="input1"
            v-model="emilinput4"
            placeholder="邀请码（没有可以不填）"
          ></el-input>
        </div>
        <div class="reg-btn">
          <el-button type="success" class="btn-sub" @click="regBtn"
            >注册</el-button
          >
        </div>
        <div class="reg-deal">
          <el-checkbox v-model="Regchecked">本人已年满18周岁</el-checkbox>
        </div>
        <div class="reg-deal">
          <el-checkbox v-model="Regchecked1">
            我已经阅读并接受本站的<strong @click="goAgreement">用户协议</strong
            >和<strong @click="goPrivacy">隐私条款</strong>。
          </el-checkbox>
        </div>
        <div class="go-login" @click="goLogin">已有账号?点击登录>></div>
      </div>
    </div>

    <!--登录盒子-->
    <div class="login" v-if="$store.state.loginState">
      <div class="reg-warp">
        <div class="btn-x" @click="hideLogin">X</div>
        <div class="login-title">
          <span
            v-for="item in loginMethods"
            :key="item.key"
            @click="loginKey = item.key"
            :style="{
              color: loginKey == item.key ? '#ffc400' : '#666',
            }"
          >
            {{ item.title }}
          </span>
        </div>
        <div class="reg-hint" v-if="loginHintState">{{ loginHintText }}</div>
        <div class="input" v-if="loginKey == 'account'">
          <el-input
            class="input1"
            v-model="account"
            placeholder="手机号码或邮箱"
          ></el-input>
          <el-input
            class="input1"
            v-model="password"
            placeholder="密码"
            type="password"
          ></el-input>
        </div>
        <div class="input" v-else>
          <el-input
            class="input1"
            v-model="account"
            placeholder="手机号码"
          ></el-input>
          <div class="input1-warp">
            <el-input
              class="input1"
              v-model="phoneinput33"
              placeholder="验证码"
            ></el-input>
            <button
              class="getCode"
              @click="
                getCodeShow = true;
                validateCodeType = 'login';
              "
              :disabled="!phonecodeState"
            >
              <i v-if="loadingReg" class="el-icon-loading"></i>
              {{ phonecodeState ? "获取验证码" : phonecodeTime + " s" }}
            </button>
          </div>
        </div>
        <div class="login-pass">
          <el-checkbox class="login-pass1" v-model="loginChecked"
            >记住登录</el-checkbox
          >
          <span @click="goForget">忘记密码</span>
        </div>
        <div class="reg-deal">
          <el-checkbox v-model="Regchecked">本人已年满18周岁</el-checkbox>
        </div>
        <div class="reg-deal">
          <el-checkbox v-model="Regchecked1">
            我已经阅读并接受本站的<strong @click="goAgreement">用户协议</strong
            >和<strong @click="goPrivacy">隐私条款</strong>。
          </el-checkbox>
        </div>
        <div
          class="reg-btn ripple"
          id="ripple"
          @click="loginKey == 'account' ? getLogin() : getCodeLogin()"
        >
          <el-button type="success" class="btn-sub" :class="loging"
            >登录</el-button
          >
        </div>
        <div class="go-login" @click="goReg">还没账号?点击注册>></div>

        <!--  <div class="login-rest">
          <span>—— 其他方式登录 ——</span>
          <img src="../assets/img/steam.png" @click="login_steam"/>
        </div>
        <div class="login-hint">
          <span>中国大陆用户访问 Steam 需要借助智能加速</span>
          <span>器 ( 否则库存功能和饰品充值功能会无法使用)</span>
        </div>-->
      </div>
    </div>

    <!-- 忘记密码1 -->
    <div class="reg" v-if="forgetBox">
      <div class="reg-warp">
        <div class="btn-x" @click="hideForgetBox">X</div>
        <div class="reg-sel">
          <span>忘记密码</span>
        </div>
        <div class="reg-hint" v-if="forgetHint">{{ forgetHintText }}</div>
        <div class="input">
          <el-input
            class="input1"
            v-model="forgetNum"
            placeholder="手机号码或邮箱"
          ></el-input>
          <el-input
            class="input1"
            v-model="forgetPass"
            placeholder="重置密码"
            type="password"
          ></el-input>
          <div class="input1-warp">
            <el-input
              class="input1"
              v-model="forgetCode"
              placeholder="验证码"
            ></el-input>
            <button
              class="getCode"
              @click="getForgetCode"
              :disabled="!forgetcodeState"
            >
              {{ forgetcodeState ? "获取验证码" : forgetcodeTime + " s" }}
            </button>
          </div>
        </div>
        <div class="reg-btn">
          <el-button type="success" class="btn-sub" @click="reset"
            >重置密码</el-button
          >
        </div>
        <div class="go-login" @click="goLogin1">想起密码了>></div>
      </div>
    </div>

    <!--登录完成右边导航-->
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :append-to-body="true"
      :before-close="handleClose"
      size="250px"
      class="sty"
    >
      <div class="sty-btn">
        <span @click="goexchange" class="bg-blue">钻石兑换金币</span>
        <span @click="goPayment" class="bg-org">充值</span>
      </div>
      <div class="sty-menu">
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#30313f"
          text-color="#fff"
          @select="gopath1"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="Dota">
            <i class="el-icon-s-goods"></i>
            <span slot="title">饰品背包</span>
          </el-menu-item>
          <el-menu-item index="Bill">
            <i class="el-icon-s-order"></i>
            <span slot="title">我的账单</span>
          </el-menu-item>
          <el-menu-item index="Spread">
            <i class="el-icon-s-promotion"></i>
            <span slot="title">活动推广</span>
          </el-menu-item>
          <el-menu-item index="Me">
            <i class="el-icon-s-custom"></i>
            <span slot="title">个人中心</span>
          </el-menu-item>
          <el-menu-item index="Inform">
            <i class="el-icon-message-solid"></i>
            <span slot="title">取回记录</span>
          </el-menu-item>
          <el-menu-item index="Vip">
            <i class="el-icon-s-goods"></i>
            <span slot="title">VIP福利</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="sty-next" @click="nextLogin">退出登录</div>
    </el-drawer>

    <!-- <div :class="[rightBar ? 'bar-show' : 'bar-hidden', 'right-bar']">
      <div class="switch" @click="switchBar()">
        <img src="../assets/img/menu-show.svg" alt="" />
      </div>
      <div class="btn-group">
        <div class="btn-hong xrfl" @click="openXrfl">
          <img
            src="../assets/img/new_logo.png"
            style="width: 55px; height: auto; margin-bottom: 30px"
          />
        </div>
        <div class="btn-hong" @click="openTaskCenter">
          <img
            src="../assets/task/renwuzhongxin.c91bd1a3.png"
            style="width: 55px; height: auto; margin-bottom: 30px"
          />
        </div>
        <div class="btn-hong" @click="openHongbao">
          <img src="../assets/img/rightpop/265.gif" />
        </div>
        <div class="btn" @click="qqGroup()">
          <img src="../assets/img/qq.svg" alt="" />
          <span>加群有奖</span>
        </div>
        <div class="btn" @click="backAction('click')">
          <div class="tip-num" v-if="num > 0">{{ num }}</div>
          <img src="../assets/img/back.svg" alt="" />
          <span>取回助手</span>
        </div>
      </div>
    </div> -->

    <el-drawer
      title="我是标题"
      :visible.sync="rightBarDrawer"
      direction="rtl"
      :size="quhuiSize"
      :before-close="handleClose"
      class="quhui-box"
    >
      <div class="give-box">
        <ul v-for="(item, index) in tableData" :key="index">
          <li>
            <div class="give-true" v-if="item.steamNameAnother">
              <div class="give-left">
                <div class="give-img">
                  <img :src="item.steamAvatarAnother" />
                </div>
                <div class="give-text">
                  <span>{{ item.steamNameAnother }}</span>
                  <span>加入steam时间：{{ item.steamCreateTimeAnother }}</span>
                </div>
              </div>
              <div
                class="give-right"
                @click="takeOffer(item.steam_receive_url)"
              >
                接受报价
              </div>
            </div>
            <div class="give-false" v-if="!item.steamNameAnother">
              <div class="give-false-left">
                <img :src="item.img" />
                <span>{{ item.name }}</span>
              </div>
              <div class="give-false-right">待发货</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="give-box1" v-if="tableData.length == 0">没有进行中的报价</div>
      <div class="func">
        <div class="hidden" @click="funcHidden">全部隐藏</div>
        <div class="refresh" @click="funcRefresh">
          <img src="../assets/img/refresh.svg" />
        </div>
      </div>
    </el-drawer>

    <!-- 红包 未领取 -->
    <div class="hongbao" v-if="hongbaoState1">
      <span class="hong-x" @click="hideHongbao1"
        ><i class="el-icon-circle-close"></i
      ></span>
      <div class="hongbao-input">
        <img src="../assets/img/hongbao/hong1.png" />
        <div class="input">
          <span class="span1"
            >加QQ群：<strong>313602570</strong>领更多福利</span
          >
          <span class="span2">/</span>
          <input
            class="input"
            placeholder="请输入红包口令"
            type="text"
            v-model="hongbaoText"
          />
        </div>
      </div>
      <div class="hongbao-btn" @click="getHongbao">
        <img src="../assets/img/hongbao/hong2.png" />
      </div>
    </div>

    <!-- 红包 已领取 -->
    <div class="hongbao1" v-if="hongbaoState2">
      <span class="hong-x" @click="hideHongbao2"
        ><i class="el-icon-circle-close"></i
      ></span>
      <div class="hong-list">
        <img class="hong-back" src="../assets/img/hongbao/hong3.png" />
        <div class="hong-text">
          <div class="hong1">恭喜您获得</div>
          <div class="hong2">
            <img src="../assets/img/hongbao/hong4.png" />
            <span>{{ my_hongbao }}</span>
          </div>
          <div class="hong3">
            加入QQ<strong>313602570</strong>群不定期发红包福利
          </div>
          <div class="hong4">
            <img src="../assets/img/hongbao/hong5.png" />
          </div>
          <div class="hong5">
            <img class="hong5-img" src="../assets/img/hongbao/hong6.png" />
            <ul class="hong5-ul">
              <li v-for="(item, index) in hongbaoData" :key="index">
                <span
                  ><strong>{{ item.name }}</strong
                  >领取了<img src="../assets/img/hongbao/hong4.png" /><strong>{{
                    item.amount
                  }}</strong
                  >红包</span
                >
              </li>
            </ul>
            <img class="hong5-img" src="../assets/img/hongbao/hong7.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 左侧导航 -->
    <!-- <div class="nav2" v-if="showMenuState">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          @click="goMenu(item.id, item.path)"
        >
          <img
            :class="item.selState ? '' : 'img1'"
            v-if="item.selState"
            :src="item.img1"
          />
          <img class="img2" v-if="!item.selState" :src="item.img2" />
          <img :class="item.selState ? 'img4' : 'img3'" :src="item.img1" />
        </li>
      </ul>
    </div> -->

    <BaseSlider
      @retrieveTheAssistant="backAction('click')"
      @hongbao="openHongbao"
      @newManChest="openXrfl"
    />

    <div class="pass-boxs" v-if="passState">
      <div class="pass-box-context">
        <span class="pass-hint" @click="hidePass"
          ><i class="el-icon-close"></i
        ></span>
        <div class="pass-title">输入兑换钻石数量</div>
        <div class="input">
          <input type="number" v-model="coin" />
        </div>
        <div class="all-diamond" @click="coin = Number(masonry)">所有钻石</div>
        <div class="pass-btn">
          <el-button type="warning" @click="exchange">确认</el-button>
        </div>
      </div>
    </div>

    <!-- 验证码登录 -->
    <div class="get-register-code" v-if="getCodeShow">
      <div class="register-content">
        <div class="info">
          <el-input
            class="input1"
            v-model="yzm"
            placeholder="请输入图形验证码"
          ></el-input>
          <div
            @click="refreshCode()"
            class="code getCodes"
            style="
              cursor: pointer;
              margin-left: 10px;
              margin-top: 5px;
              border-radius: 8px;
            "
            title="点击切换验证码"
          >
            <SIdentify :identifyCode="identifyCode"></SIdentify>
          </div>
        </div>
        <div class="btn">
          <div @click="getCodeShow = false">取消</div>
          <div @click="checkValidateCode">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import Utils from "./../assets/js/util.js";
import { SIdentify } from "./common";
import { BaseSlider } from "@/views/common";
import BaseHeader from "@/views/common/base-header/base-header.vue";
import { EXPIRE_TIME } from "@/config";
export default {
  name: "Home",
  directives: { Clickoutside },
  components: {
    SIdentify,
    BaseSlider,
    BaseHeader,
  },
  data() {
    return {
      passState: false,
      loadingReg: false,
      hongbaoData: [],
      my_hongbao: "",
      hongbaoid: "",
      quhuiSize: "680px",
      windowWidth: document.body.clientWidth,
      showMenuState: false,
      hongbaoText: "",
      hongbaoState1: false,
      hongbaoState2: false,
      tableData: [],
      rightBarDrawer: false,
      rightBar: true,
      coin: "",
      masonry: "",
      loading: false,
      forgetHint: false,
      forgetHintText: "",
      forgetBox: false,
      forgetNum: "",
      forgetPass: "",
      forgetCode: "",
      forgetcodeState: true,
      forgetcodeTime: 60,
      forgettimer: null,

      loginHintState: false,
      loginHintText: "",
      regHint: false,
      regHintText: "",
      Index: "Index",
      name: "盲盒大厅",
      regBox: false,
      phoneregBox: true,
      emilregBox: false,
      phonecodeState: true,
      phonecodeTime: 60,
      phonetimer: null,
      emilcodeState: true,
      emilcodeTime: 60,
      emiltimer: null,
      account: "",
      password: "",
      phoneinput1: "",
      phoneinput2: "",
      phoneinput3: "",
      phoneinput4: "",
      emilinput1: "",
      emilinput2: "",
      emilinput3: "",
      emilinput4: "",

      Regchecked: false,
      Regchecked1: false,

      me: {},

      loginfalse: "true", //登录状态
      loginChecked: false, //登录
      loginBox: false,
      drawer: false, //右侧导航状态

      language: false, //语言
      languageImg: require("../assets/img/13mdpi.png"),
      languageText: "简体中文",
      languageList: [
        { url: require("../assets/img/13mdpi.png"), name: "简体中文" },
        { url: require("../assets/img/yinguo.png"), name: "English" },
      ],
      menu: [
        {
          id: 1,
          img1: require("../assets/95/icon1.png"),
          img2: require("../assets/95/icon1.png"),
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          value: "首页",
        },
        {
          id: 2,
          img1: require("../assets/95/icon5.png"),
          img2: require("../assets/95/icon5.png"),
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "盲盒对战",
        },
        {
          id: 3,
          img1: require("../assets/95/icon3.png"),
          img2: require("../assets/95/icon3.png"),
          selState: false,
          path: "Arena",
          child: ["Arena", "ArenaRoom"],
          value: "ROLL房",
        },
        {
          id: 4,
          img1: require("../assets/95/icon4.gif"),
          img2: require("../assets/95/icon4.gif"),
          selState: false,
          path: "Ornament",
          child: [
            "Ornament",
            "OrnamentOpen",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          value: "幸运饰品",
        },
        {
          id: 5,
          img1: require("../assets/95/icon7.png"),
          img2: require("../assets/95/icon7.png"),
          selState: false,
          path: "Roll",
          child: ["Roll"],
          value: "饰品商城",
        },
        {
          id: 6,
          img1: require("../assets/95/icon6.png"),
          img2: require("../assets/95/icon6.png"),
          selState: false,
          path: "Spread",
          child: ["Spread"],
          value: "活动推广",
        },
        {
          id: 7,
          img1: require("../assets/95/icon2.png"),
          img2: require("../assets/95/icon2.png"),
          selState: false,
          path: "Dota",
          child: ["Dota"],
          value: "饰品背包",
        },
        {
          id: 9,
          img1: require("../assets/95/fuli.png"),
          img2: require("../assets/95/fuli66.png"),
          selState: false,
          path: "activity",
          child: ["activity"],
          value: "福利活动",
        },
        // {
        //   id: 8,
        //   img1: require("../assets/img/nav2/b7.png"),
        //   img2: require("../assets/img/nav2/b77.png"),
        //   selState: false,
        //   path: "Payment",
        //   child: ["Payment"],
        //   value: '充值'
        // },
      ],
      yidongmenu: [
        {
          id: 1,
          img1: require("../assets/95/icon1.png"),
          img2: require("../assets/95/icon1.png"),
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          value: "首页",
        },
        {
          id: 2,
          img1: require("../assets/img/nav2/b4.png"),
          img2: require("../assets/img/nav2/b4.png"),
          selState: false,
          path: "Ornament",
          child: [
            "Ornament",
            "OrnamentOpen",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          value: "幸运饰品",
        },
        {
          id: 3,
          img1: require("../assets/img/nav2/b2.png"),
          img2: require("../assets/img/nav2/b2.png"),
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "拼箱",
        },
        {
          id: 4,
          img1: require("../assets/img/nav2/b7.png"),
          img2: require("../assets/img/nav2/b7.png"),
          selState: false,
          path: "Payment",
          child: ["Payment"],
          value: "充值",
        },
        {
          id: 5,
          img1: require("../assets/img/nav2/b8.png"),
          img2: require("../assets/img/nav2/b8.png"),
          selState: false,
          path: "Dota",
          child: ["Dota"],
          value: "背包",
        },
        {
          id: 6,
          img1: require("../assets/95/icon3.png"),
          img2: require("../assets/95/icon3.png"),
          selState: false,
          path: "arena",
          child: ["arena"],
          value: "roll房",
        },
        {
          id: 7,
          img1: require("../assets/img/nav2/b5.png"),
          img2: require("../assets/img/nav2/b5.png"),
          selState: false,
          path: "Roll",
          child: ["Roll"],
          value: "商城",
        },
        {
          id: 8,
          img1: require("../assets/img/nav2/fuli66.png"),
          img2: require("../assets/img/nav2/fuli66.png"),
          selState: false,
          path: "Vip",
          child: ["Vip"],
          value: "福利",
        },
        {
          id: 9,
          img1: require("../assets/img/nav2/fuli.png"),
          img2: require("../assets/img/nav2/fuli66.png"),
          selState: false,
          path: "activity",
          child: ["activity"],
          value: "福利活动",
        },
      ],
      showNav: true,
      code: "",
      inputCode: true,
      giveBillie: 0,
      num: 0,
      money: 0,
      loging: "",
      loginReturn: [],

      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ], //根据实际需求加入自己想要的字符
      loginMethods: [
        {
          key: "account",
          title: "密码登录",
        },
        {
          key: "code",
          title: "验证码登录",
        },
      ],
      loginKey: "account",
      yzm: "",
      phoneinput33: "",
      // 验证码弹窗
      getCodeShow: false,
      // 验证码弹窗登录类型
      validateCodeType: "login",
    };
  },
  watch: {
    //监听路由
    $route(to, from) {
      var path = to.name;
      if (path == "Index") {
        this.name = "盲盒大厅";
      } else if (path == "Lucky") {
        this.name = "盲盒对战";
      } else if (path == "Arena") {
        this.name = "免费皮肤";
      } else if (path == "Roll") {
        this.name = "饰品商城";
      } else if (path == "Ornament") {
        this.name = "幸运饰品";
      }
      this.hongbaoState1 = false;
      this.hongbaoState2 = false;
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].child.includes(path)) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      for (let i = 0; i < this.yidongmenu.length; i++) {
        if (this.yidongmenu[i].child.includes(path)) {
          this.yidongmenu[i].selState = true;
        } else {
          this.yidongmenu[i].selState = false;
        }
      }
    },
    //监听手机验证码
    phonecodeState(val) {
      if (val == false) {
        this.phonetimer = setInterval(() => {
          this.phonecodeTime--;
        }, 1000);
      }
    },
    //监听手机验证码时间
    phonecodeTime(val) {
      if (val == 0) {
        this.phonecodeState = true;
        this.phonecodeTime = 60;
        clearInterval(this.phonetimer);
      }
    },
    //监听邮箱验证码
    emilcodeState(val) {
      if (val == false) {
        this.emiltimer = setInterval(() => {
          this.emilcodeTime--;
        }, 1000);
      }
    },
    //监听邮箱验证码时间
    emilcodeTime(val) {
      if (val == 0) {
        this.emilcodeState = true;
        this.emilcodeTime = 60;
        clearInterval(this.emiltimer);
      }
    },
    //监听忘记密码验证码
    forgetcodeState(val) {
      if (val == false) {
        this.forgettimer = setInterval(() => {
          this.forgetcodeTime--;
        }, 1000);
      }
    },
    //监听忘记密码验证码时间
    forgetcodeTime(val) {
      if (val == 0) {
        this.forgetcodeState = true;
        this.forgetcodeTime = 60;
        clearInterval(this.forgettimer);
      }
    },
  },
  created() {
    //this.selfLogin();
    // this.refreshCode();
  },
  mounted() {
    let codeInfo = this.GetUrlParam("code");
    this.code = codeInfo ? codeInfo.split("#")[0] : "";
    this.inputCode = this.code ? false : true;
    this.getActive();
    this.backAction();
    //判断屏幕宽度
    if (this.windowWidth < 1024) {
      this.quhuiSize = "100%";
      this.showMenuState = true;
      this.showNav = false;
    }
    this.$bus.$on("loading", (e) => {
      this.loading = e;
    });

    // console.log(this.showMenuState,this.show768);
    //查看缓存 自动登录
    this.selfLogin();
    //路由名称
    this.Index = this.$route.name;

    for (let i = 0; i < this.menu.length; i++) {
      if (this.menu[i].child.includes(this.Index)) {
        this.menu[i].selState = true;
      } else {
        this.menu[i].selState = false;
      }
    }
    let _this = this;
    Utils.$on("money", function (money) {
      // console.log(money);
      // this.money = money
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.total_amount = money;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("masonry", function (masonry) {
      // console.log(money);
      // this.money = money
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      //userInfo.total_amount = money
      userInfo.masonry = masonry;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("img", function (img) {
      console.log(img);
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.img = img;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("login", function (data) {
      console.log(data);
      _this.selfLogin();
    });
  },
  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length - 1)
        ];
      }
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    openXrfl() {
      this.$router.push({
        path: "/Openbox?box_id=62",
      });
    },
    clickOut() {
      this.showNav == false;
      // if (this.windowWidth < 1024) {
      //   this.showNav = false
      // }
    },
    //左侧导航
    goMenu(id, path) {
      if (this.windowWidth < 1024) {
        this.showNav = !this.showNav;
      }
      for (let i = 0; i < this.menu.length; i++) {
        if (id == this.menu[i].id) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      this.$router.push({
        path: `/${path}`,
      });
    },
    //左侧导航
    goNavMenu(id, path) {
      for (let i = 0; i < this.yidongmenu.length; i++) {
        if (id == this.yidongmenu[i].id) {
          if (this.yidongmenu[i].value == "口令") {
            this.openHongbao();
            return;
          }
          this.yidongmenu[i].selState = true;
        } else {
          this.yidongmenu[i].selState = false;
        }
      }
      this.$router.push({
        path: `/${path}`,
      });
    },
    switchBar() {
      this.rightBar = !this.rightBar;
    },
    qqGroup() {
      // window.open("https://jq.qq.com/?_wv=1027&k=DRGtGaXu");
      // https://qm.qq.com/cgi-bin/qm/qr?k=9KCG2IpBHLC40Qz19ClpCbqC7fohaLMY&jump_from=webapi"
      // window.open("https://qm.qq.com/cgi-bin/qm/qr?k=9KCG2IpBHLC40Qz19ClpCbqC7fohaLMY&jump_from=webapi");
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=SFm9jbJ2EcdK532ENRLXqt-s3y01VMPO&jump_from=webapi&authKey=Dtb01dh8CYbe7ksNW4x2QvBziiPhcYsnUNteDrnCgUNBQTXy1F4aBdWBNetjpNii"
      );
    },
    getActive() {
      let param = {
        player_id: localStorage.getItem("id"),
      };
      this.$axios
        .post("/index/User/giveAboutRecharge", this.$qs.stringify(param))
        .then((res) => {
          if (res.data.status == 1) {
            if (res.data.data.new) {
              res.data.data.recharge_activity.forEach((e) => {
                e.type == 1 ? (this.giveBillie = e.billie) : "";
                e.type == 2 ? (this.giveBillie = e.billie) : "";
              });
            } else {
              res.data.data.recharge_activity.forEach((e) => {
                e.type == 2 ? (this.giveBillie = e.billie) : "";
              });
            }
          }
        });
    },
    gotoNav() {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].id == 1) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      console.log(this.menu);
      this.$router.push({ path: `/Index` });
    },
    //取回助手
    backAction(click) {
      if (click) {
        this.rightBarDrawer = true;
      }
      let param = {
        page: 1,
        pageSize: 10,
      };
      this.$axios
        .post("/index/User/getRetrieveStatus", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.status == 1) {
            this.tableData = data.data.list;
            this.num = data.data.list.length;
          } else {
            this.tableData = [];
            this.num = 0;
          }
        });
    },
    //单个取回
    takeOffer(url) {
      window.open(url, "_blank");
    },
    tableRowStyle({ row, rowIndex }) {
      return "background-color: red";
    },
    handleClose() {},
    funcHidden() {
      this.rightBarDrawer = false;
    },
    funcRefresh() {
      this.backAction();
    },
    //选取语言
    getLanguage() {
      this.language = !this.language;
    },
    //改变语言
    changeLanguage(index) {
      this.languageImg = this.languageList[index].url;
      this.languageText = this.languageList[index].name;
      this.language = false;
    },
    //菜单路径跳转
    gopath(key) {
      if (this.$store.state.token) {
        this.$router.push({
          path: `/${key}`,
        });
      } else {
        this.$store.commit("getLogin", true);
      }
    },
    // 跳转用户协议
    goAgreement() {
      this.regBox = false;
      this.$router.push({ path: `/Agreement` });
    },
    // 跳转隐私条款
    goPrivacy() {
      this.regBox = false;
      this.$router.push({ path: `/Privacy` });
    },
    //跳转到疑问解答
    goDoubt() {
      this.$router.push({
        path: `/Doubt`,
      });
    },
    //兑换弹窗
    goexchange() {
      this.passState = true;

      this.drawer = false;
    },

    //钻兑换金币
    exchange() {
      if (Number.parseFloat(this.coin) > Number.parseFloat(this.masonry)) {
        this.$message.error("钻石数量不足");
        return;
      }
      var _this = this;
      let param = {
        coin: this.coin,
      };
      this.$axios
        .post("index/User/exchangenew", this.$qs.stringify(param))
        .then((res) => {
          var newMoney = parseFloat(this.money) + parseFloat(param.coin);
          var newMasonry = parseFloat(this.masonry) - parseFloat(param.coin);
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.total_amount = newMoney;
          userInfo.masonry = newMasonry;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          _this.selfLogin();
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            //this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //隐藏密码框
    hidePass() {
      this.passState = false;
    },
    //右侧菜单跳转饰品商城
    goRoll() {
      this.$router.push({
        path: `/Roll`,
      });
      this.drawer = false;
    },
    //跳转充值界面
    goPayment() {
      this.$router.push({
        path: `/Payment`,
      });
      this.drawer = false;
    },
    //右侧菜单路径
    gopath1(key, keyPath) {
      this.$router.push({
        path: `/${key}`,
      });
      this.drawer = false;
    },
    //注册盒子显示隐藏
    showReg() {
      this.regBox = true;
    },
    hideReg() {
      this.regBox = false;
    },
    //点击手机或邮箱注册
    phoneReg() {
      this.phoneregBox = true;
      this.emilregBox = false;
    },
    emilReg() {
      this.phoneregBox = false;
      this.emilregBox = true;
    },

    //忘记密码
    goForget() {
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
      this.forgetBox = true;
    },
    goLogin1() {
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
      this.forgetBox = false;
    },
    hideForgetBox() {
      this.forgetBox = false;
    },
    getForgetCode() {
      let param = {
        account: this.forgetNum,
      };
      this.$axios
        .post("index/login/sendCodeByFindPass", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.forgetcodeState = false;
            this.forgetHint = false;
          } else {
            this.forgetHint = true;
            this.forgetHintText = data.msg;
          }
        });
    },
    reset() {
      let param = {
        account: this.forgetNum,
        code: this.forgetCode,
        password: this.forgetPass,
      };
      this.$axios
        .post("index/login/findPassword", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.status == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.forgetBox = false;
            // this.loginBox = true;
            this.$store.commit("getLogin", true);
          } else {
            this.forgetHint = true;
            this.forgetHintText = data.msg;
          }
        });
    },

    // 获取参数
    GetUrlParam(name) {
      var url = window.location.href;
      let params = url.substr(url.lastIndexOf("?") + 1).split("&");
      for (let i = 0; i < params.length; i++) {
        let param = params[i];
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        if (key === name) {
          return value;
        }
      }
    },

    //点击注册按钮
    regBtn() {
      if (!this.Regchecked || !this.Regchecked1) {
        this.$message({
          message: "请仔细阅读用户协议并勾选",
          type: "warning",
        });
        return;
      }

      if (!this.phoneregBox) {
        this.$message({
          message: "后续将会开放邮箱注册",
          type: "warning",
        });
        return;
      }

      let _this = this;
      var a = this.GetUrlParam("code");
      if (a) {
        var code = a.split("#")[0];
        var param = {
          account: _this.phoneinput1,
          code: _this.phoneinput3,
          password: _this.phoneinput2,
          invite_code: code,
        };
      } else {
        var param = {
          account: _this.phoneinput1,
          code: _this.phoneinput3,
          password: _this.phoneinput2,
          invite_code: this.phoneinput4,
          type: "input",
        };
      }
      _this.$axios
        .post("/index/Register/checkCodenew", _this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res);
          let data = res.data;
          if (data.status == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.regBox = false;

            let param = {
              account: this.phoneinput1,
              password: this.phoneinput2,
            };
            this.$axios
              .post("/index/Login/Login", this.$qs.stringify(param))
              .then((res) => {
                let data = res.data;
                //  console.log(data);
                if (data.status == 1) {
                  localStorage.setItem("csgoNum", this.phoneinput1);
                  localStorage.setItem("csgoPass", this.phoneinput2);
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );
                  this.$store.commit("setUserToken", data.data.token);
                  this.selfLogin();
                  // this.loginBox = false;
                  this.$store.state.mobile = data.data.mobile;
                  this.$store.commit("getLogin", false);
                  this.loginfalse = false;
                  this.regHint = false;
                  this.$store.commit("getId", data.data);
                  this.me = data.data;
                }
              });
          } else {
            this.regHint = true;
            this.regHintText = data.msg;
          }
        });
    },
    getemilCode() {
      //this.emilcodeState = false;
      this.$message({
        message: "后续将会开放邮箱注册",
        type: "warning",
      });
    },

    //显示隐藏登录框
    showLogin() {
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
    },
    hideLogin() {
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
    },

    //点开登录 注册盒子
    goLogin() {
      this.regBox = false;
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
    },
    goReg() {
      this.regBox = true;
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
    },
    //自动登录
    // selfLogin() {
    //   let num = localStorage.getItem("csgoNum");
    //   let pass = localStorage.getItem("csgoPass");
    //   if (num) {
    //     let param = {
    //       account: num,
    //       password: pass,
    //     };
    //     this.$axios
    //       .post("/index/Login/Login", this.$qs.stringify(param))
    //       .then((res) => {
    //         let data = res.data;
    //         //  console.log(data);
    //         if (data.status == 1) {
    //           localStorage.setItem("csgoNum", num);
    //           localStorage.setItem("csgoPass", pass);
    //           // this.loginBox = false;
    //           this.$store.state.mobile = data.data.mobile;
    //           this.$store.commit("getLogin", false);
    //           this.loginfalse = false;
    //           this.regHint = false;
    //           this.$store.commit("getId", data.data);
    //           this.me = data.data;
    //         }
    //       });
    //   }
    // },
    selfLogin() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo) {
        this.me = userInfo;
        this.money = userInfo.total_amount;
        this.masonry = userInfo.masonry;
        this.loginfalse = false;
        this.regHint = false;
      }
    },
    //steam登录
    login_steam() {
      let _this = this;
      let param = {
        steam_login: "steam_login",
      };
      _this.$axios
        .post("/index/Login/steam_login", _this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data.data);
          let re = _this.isUrl(res.data.data);
          if (re) {
            // window.open(res.data.data,'_blank');
            window.location.href = res.data.data;
            return;
          }
          return;
          // window.location.href='/'
          let data = res.data;
          if (res.data.status == 1) {
            localStorage.setItem("csgoNum", _this.account);
            localStorage.setItem("csgoPass", _this.password);
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            //_this.loginBox = false;
            setTimeout(() => {
              _this.$store.state.mobile = data.data.mobile;
              _this.$store.commit("getLogin", false);
              _this.loginfalse = false;
              _this.regHint = false;
              _this.$store.commit("getId", data.data);
              _this.me = data.data;
              _this.selfLogin();
              _this.loging = "";
            }, 500);
          } else {
            _this.loginHintState = true;
            _this.loginHintText = data.msg;
            setTimeout(() => {
              _this.loging = "";
            }, 500);
          }
        });
    },
    isUrl(url) {
      var reg = "[a-zA-z]+://[^\s]*"; //正则
      if (url.length > 0) {
        var reg_test = new RegExp(reg);
        var result = reg_test.test(url);
        console.log(result);
        if (result != 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    //登录按钮 记住账号密码
    getCodeLogin(event) {
      if (!this.Regchecked || !this.Regchecked1) {
        this.$message({
          message: "请仔细阅读用户协议并勾选",
          type: "warning",
        });
        return;
      }
      let _this = this;
      let param = {
        account: _this.account,
        code: _this.phoneinput33,
      };
      _this.loging = "loging";
      // _this.$axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      _this.loginReturn;
      _this.$axios
        .post("/index/Register/checkloginCodenew", _this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          _this.loginReturn = res.data;
          if (data.status == 1) {
            localStorage.setItem("csgoNum", this.account);
            localStorage.setItem("csgoPass", this.password);
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            //_this.loginBox = false;
            setTimeout(() => {
              _this.$store.state.mobile = data.data.mobile;
              _this.$store.commit("getLogin", false);
              _this.loginfalse = false;
              _this.regHint = false;
              _this.$store.commit("getId", data.data);
              _this.me = data.data;
              _this.selfLogin();
              _this.loging = "";
            }, 500);
          } else {
            this.loginHintState = true;
            this.loginHintText = data.msg;
            setTimeout(() => {
              _this.loging = "";
            }, 500);
          }
        });
    },
    //登录按钮 记住账号密码
    getLogin(event) {
      // if (this.yzm != this.identifyCode) {
      //   this.$message({
      //     message: "图形验证码错误",
      //     type: "warning",
      //   });
      //   this.refreshCode();
      //   return;
      // }
      if (!this.Regchecked || !this.Regchecked1) {
        this.$message({
          message: "请仔细阅读用户协议并勾选",
          type: "warning",
        });
        return;
      }

      if (event) {
        var ripple = document.getElementById("ripple");
        var style = document.createElement("style");
        style.innerHTML = `.ripple::after{top:${event.offsetY - 20}px;left:${
          event.offsetX - ripple.offsetWidth / 2
        }px;}`;
        document.head.appendChild(style);
      }
      let _this = this;

      let param = {
        account: _this.account,
        password: _this.password,
      };
      _this.loging = "loging";
      // _this.$axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      _this.loginReturn;
      _this.$axios
        .post("/index/Login/Loginnew", _this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          _this.loginReturn = res.data;
          if (data.status == 1) {
            localStorage.setItem("csgoNum", this.account);
            localStorage.setItem("csgoPass", this.password);
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...res.data.data,
                expire_time: new Date().getTime() + EXPIRE_TIME,
              })
            );
            //_this.loginBox = false;

            this.$store.commit("setUserToken", data.data.token);
            setTimeout(() => {
              _this.$store.state.mobile = data.data.mobile;
              _this.$store.commit("getLogin", false);
              _this.loginfalse = false;
              _this.regHint = false;
              _this.$store.commit("getId", data.data);
              _this.me = data.data;
              _this.selfLogin();
              _this.loging = "";
            }, 500);
          } else {
            this.loginHintState = true;
            this.loginHintText = data.msg;
            setTimeout(() => {
              _this.loging = "";
            }, 500);
          }
        });
    },
    //退出登录
    nextLogin() {
      this.loginfalse = true;
      this.drawer = false;
      this.$store.commit("getId", { name: "", id: "", img: "", money: "" });
      this.$store.commit("loginOut");
      this.$router.push({
        path: `/Index`,
      });
    },

    //红包
    //打开红包
    openHongbao() {
      let param = {};
      this.$axios
        .post("index/Activity/existEnvelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.status == 1) {
            this.hongbaoid = data.data.id;
            //未抢
            if (data.data.status == 0) {
              this.hongbaoState1 = true;
            } else {
              this.hongbaoState1 = true;
              //this.hongbaoList()
            }
          } else {
            if (data.msg == "参数错误") {
              this.$message({
                message: "请先登录",
                type: "warning",
              });
              this.$store.commit("getLogin", true);
            } else {
              this.$message({
                message: "暂时没有红包哦",
                type: "warning",
              });
            }
          }
        });
    },
    openTaskCenter() {
      this.$router.push({
        path: "/task-center",
      });
    },
    //红包数据
    hongbaoList() {
      let param = {
        envelope_id: this.hongbaoid,
      };
      this.$axios
        .post("index/Activity/getDetails", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.status == 1) {
            let hongbaoDataCopy = data.data.details;
            this.my_hongbao = data.data.my_envelope;
            if (hongbaoDataCopy.length > 4) {
              for (let i = 0; i < hongbaoDataCopy.length; i++) {
                if (i < 5) {
                  this.hongbaoData.push(hongbaoDataCopy[i]);
                }
              }
            } else {
              this.hongbaoData = hongbaoDataCopy;
            }
          }
        });
    },
    //领取红包
    getHongbao() {
      if (!this.hongbaoText) {
        this.$message({
          message: "请输入红包口令",
          type: "warning",
        });
        return;
      }
      // this.hongbaoState1 = false;
      // this.hongbaoState2 = true;
      let param = {
        envelope_id: this.hongbaoid,
        password: this.hongbaoText,
      };
      this.$axios
        .post("index/Activity/envelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.status == 1) {
            this.hongbaoState1 = false;
            this.hongbaoState2 = true;
            this.hongbaoList();
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
    },

    hideHongbao1() {
      this.hongbaoState1 = false;
      this.hongbaoText = "";
    },
    hideHongbao2() {
      this.hongbaoState2 = false;
    },

    //手机端打开左侧菜单
    showMenu() {
      this.showNav = !this.showNav;
      // if (this.windowWidth < 1024) {
      //   this.showNav = true;
      // }
    },
    //右侧导航关闭
    handleClose(done) {
      done();
    },
    drawerFun() {
      this.drawer = !this.drawer;
    },

    //获取验证码
    getphoneCode() {
      this.loadingReg = true;
      let _this = this;
      let param = {
        account: _this.phoneinput1,
      };
      _this.$axios
        .post("/index/Register/getCodenew", _this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res);
          let data = res.data;
          if (data.status == 1) {
            this.loadingReg = false;
            this.phonecodeState = false;
            this.regHint = false;
          } else {
            this.loadingReg = false;
            this.regHint = true;
            this.regHintText = data.msg;
          }
        });
    },

    //获取验证码
    getLoginphoneCode() {
      this.loadingLogin = true;
      let _this = this;
      let param = {
        account: _this.account,
      };
      _this.$axios
        .post("/index/Register/getloginCodenew", _this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res);
          let data = res.data;
          if (data.status == 1) {
            this.loadingLogin = false;
            this.phonecodeState = false;
            this.loginHint = false;
          } else {
            this.loadingLogin = false;
            this.loginHint = true;
            this.loginHintState = data.msg;
          }
        });
    },
    checkValidateCode() {
      if (this.yzm != this.identifyCode) {
        this.$message({
          message: "图形验证码错误",
          type: "warning",
        });
        this.refreshCode();
        return;
      }
      // 获取验证码
      if (this.validateCodeType == "login") {
        this.getLoginphoneCode();
      }

      if (this.validateCodeType == "register") {
        this.getphoneCode();
      }
      this.getCodeShow = false;
      this.$message.success("已发送验证码，请注意查收");
      this.yzm = "";
      this.refreshCode();
    },
  },

  created() {
    var _this = this;
    document.onkeydown = (e) => {
      let e1 =
        e || event || window.event || arguments.callee.caller.arguments[0];
      if (e1 && e1.keyCode == 13) {
        if (this.$store.state.loginState) {
          _this.getLogin();
        }
      }
    };
    this.refreshCode();
  },
};
</script>

<style lang="less" scoped>
.get-register-code {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .register-content {
    position: fixed;
    top: 50vh;
    left: 50vw;
    width: 80vw;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    background-color: #16162b;
    border-radius: 15px;
    background-size: 100% 100%;
    background-image: url("../assets/img/index/dialog_bg.c00c028a.png");
    .info {
      display: flex;
      align-items: center;
      padding: 20px 20px 10px 20px;
      border-bottom: 1px solid #ffc400;
      margin: 0 20px;

      &::v-deep {
        .el-input__inner {
          background-color: transparent;
          border: none;
          color: #fff;
          font-size: 18px;
          &:focus {
            // border: 1px solid #ffc400;
          }
        }
      }
    }
    .btn {
      display: flex;
      margin: 20px;
      display: flex;
      justify-content: center;
      & > div {
        width: 88px;
        background-color: #ffc400;
        height: 32px;
        display: grid;
        place-items: center;
        font-size: 14px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
.pass-boxs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  color: #fff;

  .pass-box-context {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: max-content;
    padding: 40px 40px;
    background-color: #333542;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    .pass-hint {
      position: absolute;
      right: 10px;
      top: 10px;
      i {
        font-size: 20px;
        color: #c3c3e2;
      }
      i:hover {
        cursor: pointer;
      }
    }
    .pass-title {
      display: flex;
      justify-content: center;
      color: #c3c3e2;
      font-size: 14px;
    }
    .input {
      margin-top: 15px;
      width: 100%;
      font-size: 20px;
      input {
        width: 100%;
        height: 30px;
        outline: none;
        border: none;
        color: #848492;
      }
      input:focus {
        border: none;
      }
    }

    .pass-btn {
      margin-top: 15px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}

.home {
  height: 100%;

  .nav2 {
    // position: fixed;
    // top: 50%;
    // left: 0;
    height: 62px;
    display: flex;
    // margin-top: 15px;
    // z-index: 999;
    ul {
      display: flex;
      height: 62px;
      li:nth-child(-n + 5) {
        img {
          height: 25px !important;
        }
      }
      li {
        float: left;
        display: flex;
        align-items: center;
        margin-left: 50px;
        cursor: pointer;
        img {
          display: block;
          height: 16px;
          width: auto;
        }
        .img3 {
          display: none;
          position: relative;
          // left: -40px;
        }
        .img4 {
          display: none;
        }
        div {
          color: #fff;
          font-size: 14px;
          line-height: 38px;
          margin-left: 8px;
          white-space: nowrap;
          font-weight: 700;
        }
      }
      li:nth-child(1) {
        margin-left: 0px;
      }
      li:hover {
        div {
          color: #ffc400;
        }

        // .img3 {
        //   display: block;
        // animation: run 0.5s;
        // animation-iteration-count: 1; //播放几次动画
        // animation-delay: 0s; //动画运行前等待时间
        // animation-fill-mode: forwards; //动画结束 是否保持

        // @keyframes run {
        //   0% {
        //     left: -40px;
        //   }
        //   100% {
        //     left: 0;
        //   }
        // }
        // }
        // .img1 {
        //   display: none;
        // }
        // .img2 {
        //   display: none;
        // }
      }
    }
  }
  .item-val {
    color: #ffc400 !important;
  }
  .item-val-bian {
    color: #ffc400;
  }
  .flexleft {
    display: none;
    height: 35px;
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #191e2e;
    padding-bottom: 5px;
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 12px;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .top {
    // background-image: url("../assets/img/nav/nav10.png");
    // background: rgba(23, 23, 30, 0.8);
    // background-size: 100% 100%;
    // box-shadow: 0px 5px 8px #333333;
    // background-color: #20222b;
    background-color: white;
    box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
      0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
    height: 62px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    .top-l-r {
      width: 100%;
      display: flex;
      height: 62px !important;
      justify-content: space-between;
      margin-left: -6px;
    }
    // width: calc(100% - 17px);
    .top-el-row {
      display: flex;
      // margin-top: 6px;
      align-items: center;
      height: 62px !important;
      background-color:white;
    }
    .top-btn {
      position: absolute;
      display: none;
    }

    .logo {
      display: flex;
      // justify-content: center;
      align-items: center;
      width: calc(100% - 250px);
      margin-left: 10px;
      .yidong-menu {
        display: none;
      }
      .logo-word {
        // font-style: itali;
        // font-size: 20px;
        font-size: 20px;
        font-style: italic;
        color: transparent;
        text-shadow: 0 0 5px #ffc400, 1px 1px 1px #ffc400, -1px -1px 1px #ffc400,
          0 0 10px #ffc400, 0 0 20px #ffc400;
      }
      .top-bag,
      .top-pay {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: auto;
          margin-right: 4px;
        }
        .span {
          font-size: 15px;
          color: #848492;
        }
        .span:hover {
          color: #f9e315;
          cursor: pointer;
        }
      }
      .top-pay {
        .pay-span {
          margin-left: 10px;
          color: #1a1c24;
          display: inline-block;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          background-color: #02bf4d;
          position: relative;
        }
        .pay-span::after {
          top: 50%;
          left: -4px;
          color: #02bf4d;
          width: 0;
          content: "";
          position: absolute;
          border-top: 4px solid transparent;
          margin-top: -4px;
          border-right: 4px solid;
          border-bottom: 4px solid transparent;
        }
      }
      .img1 {
        height: 50px;
        // margin-top: 5px;
        width: auto;
      }
      .zm {
        height: 40px;
        margin-top: 10px;
        width: auto;
      }
      .img2 {
        height: 20px;
        width: auto;
      }
      .img3 {
        height: 34px;
        width: auto;
      }
      .span-line {
        height: 42px;
        width: 2px;
        background-color: #999999;
        margin: 10px 15px;
      }
    }
    .top-name {
      width: max-content;
      padding-right: 10px;
      justify-content: space-between;
      align-items: center;
      // margin-top: 14px;
      float: right;
      // min-width: 190px;
      display: flex;
      .top-con {
        padding-left: 20px;
        border-left: 3px solid #999;
        display: flex;
        align-items: center;
        span {
          padding-left: 20px;
          color: #999;
        }
      }
      .top-right {
        margin-left: -10px;
        display: flex;
        align-items: center;

        .btn {
          white-space: nowrap;
          margin-right: 5px;
          padding: 6px 25px;
          font-size: 15px;
          font-weight: 700;
          color: #ffc400;
          border: 1px solid #ffc400;
        }
        .btn:hover {
          cursor: pointer;
          // background-color: #cacecc09;
        }
        .btn1 {
          color: #fff;
          // border: 1px solid #ffc400;
          font-size: 15px;
          font-weight: 700;
          padding: 5px 25px;
          color: #20212b;
          background: #ffc400;
        }
      }
      .top-right1 {
        margin-left: 0px;
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // .mess {
        //   display: flex;
        //   align-items: center;
        //   .mess-left {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     flex-direction: column;
        //     margin-right: 5px;
        //     img {
        //       width: 42px;
        //       height: 42px;
        //       border-radius: 50%;
        //       margin-right: 6px;
        //     }
        //     .mess-span1 {
        //       font-size: 12px;
        //       color: #848492;
        //       font-weight: 600;
        //     }
        //   }
        //   .mess-right {
        //     //margin-left: -5px;
        //     padding-top: 2px;
        //     display: flex;
        //     flex-direction: column;
        //     padding-right: 12px;
        //     .mess-span1 {
        //       font-size: 12px;
        //       color: #848492;
        //       font-weight: 600;
        //     }
        //     .mess-span2 {
        //       // display: flex;
        //       // align-items: center;
        //       img {
        //         width: auto;
        //         height: 20px;
        //       }
        //       p {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //       }
        //       strong {
        //         margin: 4px;
        //         margin-left: 3px;
        //         font-size: 12px;
        //         color: #f9e315;
        //       }
        //     }
        //   }
        // }

        .mess {
          display: flex;
          align-items: center;
          .mess-left {
            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-right: 6px;
            }
          }
          .mess-right {
            //margin-left: -5px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 12px;
            .mess-span1 {
              font-size: 16px;
              color: #ffffff;
              font-weight: 600;
            }
            .mess-span2 {
              display: flex;
              align-items: center;
              img {
                width: auto;
                height: 16px;
              }
              strong {
                margin: 4px;
                margin-left: 3px;
                font-size: 12px;
                color: #e9b10e;
              }
            }
          }
        }
        .top-right1-ico {
          // margin-right: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .top-right1:hover {
        cursor: pointer;
      }
      .p-r {
        display: flex;
        color: #848492;
        justify-content: flex-start;
        margin-top: 5px;
        .logo-span {
          cursor: pointer;
          display: flex;
          height: 19px;
        }
        .logo-span:hover {
          .text {
            color: #f9e315;
          }
        }

        .top-pay:nth-child(2) {
          margin-left: 12px;
        }
        .span,
        .pay-span {
          font-size: 14px;
          margin-left: 6px;
          white-space: nowrap;
        }
        .pay-span {
          margin-left: 10px;
          color: #1a1c24;
          display: inline-block;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          background-color: #02bf4d;
          position: relative;
        }
        .pay-span::after {
          top: 50%;
          left: -4px;
          color: #02bf4d;
          width: 0;
          content: "";
          position: absolute;
          border-top: 4px solid transparent;
          margin-top: -4px;
          border-right: 4px solid;
          border-bottom: 4px solid transparent;
        }
      }
    }
  }
  .isLogin {
    display: none !important;
  }
  .maringtop115 {
    margin-top: 115px;
  }
  // .maringtop75{
  //     margin-top: -75px;
  // }
  .backColor {
    min-height: calc(100vh - 62px);
    // background-color: #1a1c24;
    background-color: #ddd;
    // display: flex;
    //  background: url("../assets/new/openbox.png") no-repeat;
    //  background-size: 100%;
    // background-color: #161822;
  }
  .bot {
    height: 100%;
    display: flex;
    background-color: #25252f;
    .bot-left {
      height: 100%;
      min-width: 290px;
      max-width: 290px;
      background-color: #000;
      position: relative;
      box-shadow: 5px 0px 8px #262626;
      overflow: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;

      .imgtop {
        position: absolute;
        right: 4%;
        top: 0;
        width: 90%;
        img {
          width: 100%;
        }
      }

      .img {
        height: 45%;
        position: absolute;
        img {
          //height: 100%;
          width: 240px;
          height: 266px;
        }
        img:hover {
          cursor: pointer;
        }
        .img11 {
          position: absolute;
          height: 50%;
          width: auto;
          top: -35%;
        }
        .img12 {
          right: -27%;
        }
        .img13 {
          left: -27%;
        }
        .class3 {
          position: absolute;
          height: 50%;
          width: auto;
          top: 7%;
          right: -67%;
        }
        .img-bot {
          height: 30%;
          position: absolute;
          bottom: -15%;
          right: 20%;
          display: flex;
          flex-direction: column;

          img {
            height: 100%;
            width: auto;
          }
          img:last-child {
            margin-top: 10px;
          }
        }
      }

      .img1 {
        top: -25px;
        left: -22px;
      }
      .img2 {
        top: 150px;
        left: 73px;
      }
      .img3 {
        top: 320px;
        left: -22px;
      }
      .img4 {
        top: 490px;
        left: 73px;
      }
      .img5 {
        top: 660px;
        left: -22px;
      }
    }
    .bot-left1 {
      display: block;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 666;
    }
    //设置左侧滚动条
    .bot-left::-webkit-scrollbar {
      width: 0px;
    }
    /* .bot-left::-webkit-scrollbar-track {
      -webkit-box-shadow: inset006pxrgba(255, 255, 255, 0.3);
      border-radius: 10px;
    }*/

    .bot-right {
      width: 100%;
      // width: calc(100% - 290px);
      // height: 100%;
      height: calc(100vh - 0px);
    }
    .el-loading-spinner {
      position: relative;
    }
  }
  .input1-warp {
    position: relative;

    .getCodes {
      position: absolute;
      right: 0px;
      top: 16px;
      color: #c3c3e2;
      font-size: 14px;
      border: none;
      outline: none;
    }
    .getCode {
      position: absolute;
      right: 5px;
      top: 25px;
      color: #c3c3e2;
      font-size: 14px;
      background-color: #24252f;
      border: none;
      outline: none;
    }

    .getCode:hover {
      cursor: pointer;
    }
  }

  //注册
  .reg,
  .login {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
    height: 100%;

    .reg-warp {
      padding: 30px;
      display: block;
      color: #848492;
      width: 25%;
      background-color: #1d202d;
      // background: url("../assets/new/loginborder.png");
      background: url("../assets/95/reg.png") no-repeat;
      // background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 60px 50px;
      .btn-x {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 4px;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
      }
      .btn-x:hover {
        background-color: #444659;
        cursor: pointer;
      }
      .reg-sel {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;
        span:hover {
          cursor: pointer;
        }

        .span1 {
          //color: #848492;
          color: #ffc400;
          padding-bottom: 4px;
        }
        .span2 {
          color: #848492;
        }
      }

      .reg-hint {
        margin-top: 30px;
        padding: 10px 10px;
        background-color: #5c3c47;
        border-radius: 5px;
        color: #ff5c5c;
        font-size: 14px;
      }

      .input {
        // margin-top: 10px;

        .input1-warp {
          position: relative;

          .getCode {
            position: absolute;
            right: 5px;
            top: 25px;
            color: #c3c3e2;
            font-size: 14px;
            background-color: #1a1c28;
            border: none;
            outline: none;
          }
          .getCode:hover {
            cursor: pointer;
          }
        }

        .input1 /deep/ input.el-input__inner {
          margin-top: 15px;
          background-color: #1a1c28;
          border: none;
          color: currentColor;
        }
      }

      .reg-btn {
        width: 100%;
        margin-top: 25px;
        .btn-sub {
          width: 100%;
          background: #ffc400;
          color: #000;
          font-weight: 600;
          font-size: 16px;
          opacity: 0.9;
          border: 1px;
        }
        .btn-sub:hover {
          // background-color: #05e05d;
          opacity: 1;
          transition: 0.3s;
        }
      }
      .reg-deal {
        margin-top: 20px;
        font-size: 14px;
        color: #848492;
        strong {
          color: #fff;
          cursor: pointer;
        }
      }
      .go-login {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        color: #848492;
      }
      .go-login:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .login {
    .login-title {
      display: flex;
      justify-content: center;
      font-size: 20px;
      span {
        cursor: pointer;
        margin: 0 5px;
      }
    }

    .login-pass {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .login-pass1 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #f9e315;
        border-color: #f9e315;
      }
      .login-pass1 /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #f9e315;
      }
      span {
        font-size: 14px;
      }
      span:hover {
        cursor: pointer;
      }
    }
    .login-rest {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 14px;
      }
      img {
        margin-top: 20px;
        width: 35px;
        height: 35px;
      }
    }
    .login-hint {
      height: 90px;
      width: 100%;
      position: absolute;
      bottom: -90px;
      text-align: center;
      left: 0;
      line-height: 20px;
      background-color: #24252f;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #848492;
      font-size: 12px;
    }
  }
}
//右侧导航

.sty {
  .sty-menu {
    height: 100%;
    background-color: #30313f;
  }
  .sty-btn {
    padding: 8px;
    background-color: #1f1f26;
    display: flex;
    justify-content: space-between;
    span {
      color: #1a1c24;
      font-size: 13px;
      font-weight: 600;
      width: 100px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
    }
    span:hover {
      cursor: pointer;
    }
    /deep/ span:first-child:hover {
      background-color: #15bcf8;
    }
    /deep/ .bg-blue {
      background-color: #17b4ed;
    }
    /deep/ .bg-org:hover {
      background-color: #e9b20e;
    }
    /deep/ .bg-org {
      background-color: #f1b80a;
    }
  }
  .sty-next {
    position: absolute;
    bottom: 0;
    background-color: #24252f;
    width: 100%;
    padding: 12px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #848492;
  }
  .sty-next:hover {
    cursor: pointer;
    background-color: #2b2c37;
    color: #c3c3e2;
  }
}

/deep/ .el-drawer__wrapper {
  top: 60px;
}
/deep/ .el-drawer__header {
  display: none;
}
/deep/ .el-drawer__body {
  background-color: #30313f;
  overflow: hidden;
}
/deep/ .el-menu {
  border-right: none;
}
</style>

<style lang="less">
.right-bar {
  top: 50%;
  right: 0px;
  z-index: 700;
  position: fixed;
  background: #000;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: #30313f;
  padding: 10px 0;
  border-radius: 1px;
}
.bar-show {
  width: 60px;
  transition: width 0.7s ease-out;
  min-height: 250px;
}
.bar-hidden {
  width: 0;
  transition: width 0.7s ease-out;
  min-height: 250px;
}
.switch {
  top: 50%;
  left: -20px;
  cursor: pointer;
  position: absolute;
  margin-top: -35px;
}
.switch img {
  width: 20px;
  vertical-align: top;
}
.btn-group {
  min-width: 60px;
  .btn-hong {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .btn-hong:hover {
    cursor: pointer;
  }
  .xrfl {
    margin-top: 20px;
    animation: bounce_b-2ff1ae70 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      infinite;
  }
  @keyframes bounce_b-2ff1ae70 {
    0% {
      transform: translateY(-10px);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    cursor: pointer;
    padding: 10px 0 10px 0;
    color: #f9e315;
    font-weight: 500;
    > img {
      width: 32px;
      height: 32px;
    }
    > span {
      font-size: 12px;
      margin-top: 8px;
    }
    position: relative;
    .tip-num {
      position: absolute;
      background-color: red;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      color: #fff;
      font-size: 12px;
      top: 5px;
      left: 8px;
    }
  }
  .btn:hover,
  .btn:active {
    color: #fff;
    background-color: #f9e315;
  }
}
.func {
  position: absolute;
  display: flex;
  height: 44px;
  width: 100%;
  bottom: 0px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  border-top: 1px solid #444659;
  .hidden {
    flex: 1;
    height: 44px;
    line-height: 44px;
    background-color: #30313f;
    cursor: default;
    color: #c3c3e2;
  }
  .hidden:hover {
    background-color: #3a4050;
    color: #c3c3e2;
    cursor: pointer;
  }
  .refresh {
    flex: 1;
    height: 44px;
    position: relative;
    background-color: #30313f;

    > img {
      height: 44px;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .refresh:hover {
    color: #1a1c24;
    background-color: #3a4050;
    cursor: pointer;
  }
  .refresh:active {
    background-color: #1a1c24;
  }
}
//取回列表
.give-box {
  //background-color: #fff;
  background-color: #30313f;
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 40px;
  overflow: hidden;
  overflow-y: auto;
  ul {
    padding: 0 10px;
    li {
      margin-top: 10px;
      .give-true {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .give-left {
          display: flex;
          align-items: flex-start;
          .give-img {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .give-text {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            span:first-child {
              font-size: 14px;
              color: #c3c3e2;
            }
            span:last-child {
              font-size: 12px;
              color: #848492;
            }
          }
        }
        .give-right {
          font-size: 14px;
          background-color: #f9e315;
          padding: 8px 22px;
          border-radius: 5px;
          color: #1a1c24;
        }
        .give-right:hover {
          background-color: #f5c432;
          cursor: pointer;
        }
      }
      .give-false {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .give-false-left {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          span {
            margin-left: 10px;
            font-size: 14px;
            color: #c3c3e2;
          }
        }
        .give-false-right {
          padding: 8px 22px;
          font-size: 14px;
          color: #c3c3e2;
        }
      }
    }
  }
}
.give-box1 {
  margin-top: 30px;
  background-color: #30313f;
  width: 100%;
  height: 150px;
  line-height: 150px;
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  color: #c3c3e2;
  font-size: 16px;
}

//红包 未领取
.hongbao {
  position: fixed;
  top: 20%;
  left: 50%;
  width: 400px;
  margin-left: -150px;
  z-index: 666;

  .hong-x {
    position: absolute;
    z-index: 20;
    right: 10px;
    top: 10px;
    i {
      color: #fac3aa;
      font-size: 30px;
    }
    i:hover {
      cursor: pointer;
    }
  }

  .hongbao-input {
    position: relative;
    margin-left: 60px;
    img {
      width: 80%;
      height: auto;
    }

    .input {
      position: absolute;
      top: 35%;
      left: 50%;
      width: 180px;
      margin-left: -110px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .span1 {
        font-size: 14px;
        border-radius: 5px;
        margin-left: -30px;
        color: #fff;
        white-space: nowrap;

        strong {
          color: #faa710;
        }
      }
      .span2 {
        margin-top: 8px;
        letter-spacing: 2px;
        font-size: 20px;
        color: #faa710;
      }
      input {
        margin-top: 8px;
        height: 25px;
        // background: none;

        outline: none;
        border: 1px solid #ccc;
        color: #faa710;
      }
      input:focus {
        border: none;
      }
    }
  }
  .hongbao-btn {
    img {
      width: 100%;
      margin-left: 1px;
      height: auto;
    }
  }
  .hongbao-btn:hover {
    cursor: pointer;
  }
}

.hongbao1 {
  position: fixed;
  top: 20%;
  left: 50%;
  width: 400px;
  margin-left: -150px;
  z-index: 666;

  .hong-x {
    position: absolute;
    z-index: 20;
    right: 15px;
    top: 30px;
    i {
      color: #fac3aa;
      font-size: 30px;
    }
    i:hover {
      cursor: pointer;
    }
  }
  .hong-list {
    position: relative;
    .hong-back {
      width: 100%;
      height: auto;
    }

    .hong-text {
      position: absolute;
      top: 15%;
      left: 50%;
      width: 220px;
      margin-left: -110px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hong1 {
        font-size: 25px;
        color: #faa710;
        letter-spacing: 2px;
      }
      .hong2 {
        margin-top: 20px;
        display: flex;
        align-items: center;

        img {
          width: 50px;
          height: auto;
        }
        span {
          margin-left: 10px;
          font-size: 20px;
          color: #faa710;
        }
      }
      .hong3 {
        margin-top: 20px;
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        strong {
          color: #faa710;
        }
      }
      .hong4 {
        margin-top: 20px;
      }
      .hong5 {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .hong5-ul {
          margin: 10px 0;
          .hong5-img {
            height: 20px;
            width: auto;
          }
          li {
            display: flex;
            align-items: center;
            padding-top: 5px;
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              color: #f3836d;
              strong {
                color: #faa710;
                font-weight: 200;
              }
            }

            img {
              margin-left: 5px;
              width: 16px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
.quhui-box .el-drawer__body .el-drawer .el-drawer.ltr,
.quhui-box .el-drawer.rtl,
.quhui-box .el-drawer__container .el-drawer__body {
  background-color: transparent !important;
  /* flex: none !important; */
  box-shadow: none !important;
}
/*.el-table {
  position: fixed !important ;
  bottom: 44px !important ;
  background-color: #30313f !important;
}
.el-table th,
.el-table tr {
  background-color: #30313f !important;
}*/
</style>

<style scoped>
.ripple {
  position: relative;
  /* //隐藏溢出的径向渐变背景 */
  overflow: hidden;
  /* width: 200;
    height: 100; */
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* //设置径向渐变 */
  background-image: radial-gradient(circle, #666 15%, transparent 15.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(15, 15);
  opacity: 0;
  transition: transform 0.8s, opacity 0.8s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.6;
  /* //设置初始状态 */
  transition: 0s;
}
.loging {
  background-color: #808080 !important;
}
@media screen and (max-width: 767px) {
  .yidong-menu {
    display: inline-block !important;
  }
}
.all-diamond {
  font-size: 12px;
  color: #e6a23c;
  padding: 10px 0 0 0;
  cursor: pointer;
  text-align: end;
}
</style>
