<template>
  <div :class="['win', showNav ? '' : 'winm']">
    <div class="win-top">
      <img src="../assets/img/win1.png" v-if="showNav" />
    </div>
    <div
      :class="{
        'win-con': winList.skins_info.length == 1,
        'win-con1': winList.skins_info.length == 2,
        'win-con2': winList.skins_info.length == 3,
        'win-con3': winList.skins_info.length == 4,
        'win-con4': winList.skins_info.length == 5,
      }"
      v-if="showNav"
    >
      <ul>
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div class="win-warp">
            <div
              class="win-img"
              :style="{
                backgroundImage: 'url(' + item.background + ')',
              }"
            >
              <img :src="item.img" />
            </div>
            <div class="win-text" :title="item.name">{{ item.name }}</div>
            <div
              class="win-textt"
              @click="winexchange(index)"
              v-if="winList.skins_info.length != 1"
            >
              <span
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  cursor: pointer;
                "
              >
                <img
                  src="../assets/img/masonry.png"
                  style="width: 16px; margin: 0 5px"
                />
                {{ item.price }}
                回收
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="{
        'win-conm': winList.skins_info.length == 1,
        'win-con1m': winList.skins_info.length == 2,
        'win-con2m': winList.skins_info.length == 3,
        'win-con3m': winList.skins_info.length == 4,
        'win-con4m': winList.skins_info.length == 5,
      }"
      v-else
    >
      <ul>
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div class="win-warp">
            <div
              class="win-img"
              :style="{
                backgroundImage: 'url(' + item.background + ')',
              }"
            >
              <img :src="item.img" />
            </div>
            <div class="win-text" :title="item.name">{{ item.name }}</div>
            <div
              class="win-textt"
              @click="winexchange(index)"
              v-if="winList.skins_info.length != 1"
            >
              <span
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                "
                ><img
                  src="../assets/img/masonry.png"
                  style="width: 16px; margin-right: 2px"
                />{{ item.price }} 回收</span
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="win-bot">
      <div class="win-span1" @click="winexchange('99')">
        <span>兑换</span> <img src="../assets/img/masonry.png" /><span>{{
          total_price
        }}</span>
      </div>
      <div class="win-span2" @click="winget">放入背包</div>
    </div>
    <div class="win-x" @click="winX">X</div>
    <div class="win-back">
      <img src="../assets/img/win3.png" />
    </div>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
export default {
  props: ["winList", "winState", "player_skins_ids"],
  data() {
    return {
      //winState: true,
      list4: [],
      total_price: 0,
      windowWidth: document.body.clientWidth,
      showNav: true,
    };
  },
  methods: {
    //兑换
    winexchange(index) {
      var param = {
        player_skins_ids: this.winList.player_skins_ids,
      };
      console.log(index);
      if (index != "99") {
        param = {
          player_skins_ids: [this.winList.player_skins_ids[index]],
        };
      } else {
        this.$emit("winexchange", false);
      }
      this.$axios
        .post("/index/User/exchangeToMoneynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              //message: data.msg,
              message: "兑换成功",
              type: "success",
            });
            if (index != "99") {
              this.winList.skins_info = this.winList.skins_info.filter(
                (item, indexs) => indexs != index
              );
              var a = this.winList.player_skins_ids.splice(
                this.winList.player_skins_ids.indexOf(
                  this.winList.player_skins_ids[index]
                ),
                1
              );
              this.total_price = 0;
              for (let i = 0; i < this.winList.skins_info.length; i++) {
                this.total_price += parseFloat(
                  this.winList.skins_info[i].price
                );
              }
              this.total_price = this.total_price.toFixed(2);
            }
          } else {
            this.$message({
              //message: data.msg,
              message: "兑换失败",
              type: "warning",
            });
          }
        });
    },
    //放入背包
    winget() {
      this.$emit("winget", false);
    },
    //x掉
    winX() {
      this.$emit("winX", false);
    },
  },
  mounted() {
    // console.log(this.winList);
    for (let i = 0; i < this.winList.skins_info.length; i++) {
      this.total_price += Number(this.winList.skins_info[i].price);
    }
    this.total_price = this.total_price.toFixed(2);
    if (this.windowWidth < 1024) {
      this.showNav = false;
    }
  },
};
</script>

<style lang="less" scoped>
.winm {
  padding-bottom: 80px !important;
  overflow: hidden;
}
.win {
  position: absolute;
  top: 50vh;
  left: 50vw;
  width: 450px;
  padding: 20px;
  position: relative;
  transform: translate(-50%, -50%);

  // padding-top: 0;
  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .win-top {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 76%;
      // height: 200px;
    }
  }

  .win-x {
    position: absolute;
    top: 6%;
    right: 30px;
    color: #e9b10e;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #e9b10e;
    text-align: center;
    line-height: 20px;
  }

  .win-x:hover {
    cursor: pointer;
  }

  .win-bot {
    width: 78%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    & > div {
      flex: 1;
    }

    .win-span1 {
      width: 80%;
      height: 40px;
      border-radius: 5px;
      margin-right: 5px;
      background: url("../assets/img/anniu.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
      }

      img {
        margin-left: 20px;
        margin-right: 5px;
        width: auto;
        height: 15px;
      }
    }

    .win-span1:hover {
      cursor: pointer;
    }

    .win-span2 {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      background-color: #ffca18;
      width: 80%;
      border-radius: 5px;
      height: 40px;
      margin-left: 5px;
      line-height: 40px;
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }

  .win-con {
    display: flex;
    justify-content: center;

    ul {
      width: 60%;
      display: flex;
      justify-content: center;

      li {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;

        .win-warp {
          width: 100%;
          background-color: #e2c873;
          position: relative;

          .win-img {
            padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              height: auto;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: rgb(248, 246, 246);
          }

          .win-textt {
            display: none;
            width: 100%;
            padding: 6px 0;
            background-color: #e2c873;
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            color: #000;
            font-size: 10px;
          }
        }
      }
    }
  }

  .win-con1 {
    display: flex;
    justify-content: center;

    ul {
      margin: -5px;
      width: 80%;
      display: flex;
      flex-flow: row wrap;

      //flex-wrap: wrap;
      li {
        margin: 5px;
        width: 50%;
        border-radius: 5px;

        .win-warp {
          width: 100%;
          position: relative;

          .win-img {
            padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              height: auto;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: white;
          }

          .win-textt {
            width: 100%;
            padding: 6px 0;
            background: url("../assets/img/anniu.png") no-repeat;
            background-size: 100% 100%;
            color: rgb(15, 15, 15);
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }
      }
    }
  }

  .win-con2 {
    display: flex;
    justify-content: center;

    ul {
      margin: -5px;
      width: 80%;
      display: flex;
      flex-flow: row wrap;

      li {
        margin-top: 5px;
        overflow: hidden;
        min-width: 50%;
        max-width: 50%;
        border-radius: 5px;
        // background-color: #e2c873;

        .win-warp {
          border-radius: 5px;
          overflow: hidden;
          margin: 0 5px;
          width: 100%;
          position: relative;

          .win-img {
            // padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              border-top-right-radius: 5px;
              width: 100%;
              height: auto;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: white;
          }

          .win-textt {
            width: 100%;
            padding: 6px 0;
            background: url("../assets/img/anniu.png") no-repeat;
            background-size: 100% 100%;
            color: rgb(14, 13, 13);
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }
      }
    }
  }

  .win-con3 {
    display: flex;
    justify-content: center;

    ul {
      margin: -5px;
      width: 80%;
      display: flex;
      flex-flow: row wrap;

      li {
        // margin: 5px;
        min-width: 50%;
        max-width: 50%;

        .win-warp {
          margin: 5px;
          border-radius: 5px;
          overflow: hidden;
          // width: 100%;
          position: relative;
          .win-img {
            padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              height: auto;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: white;
          }

          .win-textt {
            width: 100%;
            padding: 6px 0;
            background: url("../assets/img/anniu.png") no-repeat;
            background-size: 100% 100%;
            color: rgb(15, 15, 15);
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }
      }
    }
  }

  .win-con4 {
    display: flex;
    justify-content: center;

    ul {
      margin: -5px;
      width: 80%;
      display: flex;
      flex-flow: row wrap;

      li {
        // margin: 5px;
        min-width: 50%;
        max-width: 50%;

        .win-warp {
          margin: 5px;
          border-radius: 5px;
          overflow: hidden;
          // width: 100%;
          position: relative;

          .win-img {
            padding: 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              height: auto;
            }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            text-overflow: ellipsis;
            position: absolute;
            width: 100px;
            text-align: center;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: 30px;
            font-size: 10px;
            color: white;
          }

          .win-textt {
            width: 100%;
            padding: 6px 0;
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            background: url("../assets/img/anniu.png") no-repeat;
            background-size: 100% 100%;
            color: rgb(2, 2, 2);
            font-size: 10px;
          }
        }
      }

      li:nth-child(1) {
        min-width: 45%;
        max-width: 45%;
        margin-left: 25%;
      }

      li:nth-child(2) {
        min-width: 50%;
        max-width: 50%;
      }
    }
  }
}
.win-conm {
  display: flex;
  justify-content: center;
  padding-top: 50px;

  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 40px 0;

    li {
      // margin: 5px;
      min-width: 40%;
      max-width: 40%;

      .win-warp {
        margin: 5px;
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        position: relative;

        .win-img {
          padding: 10px;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100px;
          text-align: center;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          background: url("../assets/img/anniu.png") no-repeat;
          background-size: 100% 100%;
          color: rgb(3, 3, 3);
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }
    }

    li:nth-child(1) {
      min-width: 60%;
      max-width: 60%;
    }

    li:nth-child(2) {
      min-width: 60%;
      max-width: 60%;
    }
  }
}
.win-con1m {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 40px 0;

    li {
      // margin: 5px;
      min-width: 40%;
      max-width: 40%;

      .win-warp {
        margin: 5px;
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        position: relative;

        .win-img {
          padding: 10px;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100px;
          text-align: center;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          background: url("../assets/img/anniu.png") no-repeat;
          background-size: 100% 100%;
          color: rgb(19, 18, 18);
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }
    }

    li:nth-child(1) {
      min-width: 40%;
      max-width: 40%;
    }

    li:nth-child(2) {
      min-width: 40%;
      max-width: 40%;
    }
  }
}
.win-con2m {
  display: flex;
  justify-content: center;
  padding-top: 110px;

  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    li {
      // margin: 5px;
      min-width: 40%;
      max-width: 40%;

      .win-warp {
        margin: 5px;
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        position: relative;

        .win-img {
          padding: 10px;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100px;
          text-align: center;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          background: url("../assets/img/anniu.png") no-repeat;
          background-size: 100% 100%;
          color: rgb(10, 10, 10);
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }
    }

    li:nth-child(1) {
      min-width: 40%;
      max-width: 40%;
    }

    li:nth-child(2) {
      min-width: 40%;
      max-width: 40%;
    }
  }
}
.win-con3m {
  display: flex;
  justify-content: center;
  padding-top: 110px;

  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    li {
      // margin: 5px;
      min-width: 40%;
      max-width: 40%;

      .win-warp {
        margin: 5px;
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        position: relative;

        .win-img {
          padding: 10px;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100px;
          text-align: center;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          background: url("../assets/img/anniu.png") no-repeat;
          background-size: 100% 100%;
          color: rgb(3, 3, 3);
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }
    }

    li:nth-child(1) {
      min-width: 40%;
      max-width: 40%;
    }

    li:nth-child(2) {
      min-width: 40%;
      max-width: 40%;
    }
  }
}
.win-con4m {
  display: flex;
  justify-content: center;
  padding-top: 50px;

  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    li {
      // margin: 5px;
      min-width: 33%;
      max-width: 33%;

      .win-warp {
        margin: 5px;
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        position: relative;

        .win-img {
          padding: 10px;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100px;
          text-align: center;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          background: url("../assets/img/anniu.png") no-repeat;
          background-size: 100% 100%;
          color: rgb(15, 15, 15);
          font-size: 10px;
        }
      }
    }

    li:nth-child(1) {
      min-width: 33%;
      max-width: 33%;
    }

    li:nth-child(2) {
      min-width: 33%;
      max-width: 33%;
    }
  }
}
@media screen and (max-width: 767px) {
  .openbox {
    .kai-box {
      padding: 0 !important;
    }

    .win-box {
      left: 0 !important;
      margin-left: 0 !important;
    }

    .win-box {
      .win {
        width: auto !important;

        .win-text {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
