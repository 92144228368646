<template>
  <div>
    <!-- <div class="openbox-page">
      <div class="mySlide"><myslide></myslide></div>
    </div> -->
    <div
      class="openbox"    
    >
      <!-- :style="{
        backgroundImage: 'url(' + img + ')',
      }" -->
      <div class="openbox yidong">
        <div class="kai-box">
          <!-- 单个盒子打开动画 v-if="openBoxState && kaiBox == 1" -->
          <div
            :class="[showNav == false ? 'kai-box-open2m' : 'kai-box-open2']"
            v-if="openBoxState && kaiBox == 1"
          >
            <div class="kai-warp11">
              <img class="dingbu" src="../assets/img/dingbu.png" alt="" />
              <img class="kaibox-line" src="../assets/img/shuxian.png" alt="" />
              <ul>
                <li
                  v-for="(item, index) in imgList"
                  :key="index"
                  :style="{
                    backgroundImage: 'url(' + item.background + ')',
                  }"
                >
                  <img :src="item.img" />
                </li>
              </ul>
              <img class="dibu" src="../assets/img/dibu.png" alt="" />
            </div>
          </div>

          <!-- 开盒子 -->
          <!-- 2个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->

          <div
            :class="[showNav == false ? 'kai-box-open22m' : 'kai-box-open22']"
            v-if="openBoxState && kaiBox == 2"
          >
            <div class="kai-warp11">
              <img class="dingbu" src="../assets/img/dingbu.png" alt="" />
              <img class="kaibox-line" src="../assets/img/shuxian.png" alt="" />
              <div
                :class="{
                  'kaibox-warp kaibox-warp2 ': kaiBox == 2,
                }"
              >
                <ul class="kaibox-ul">
                  <li
                    :class="['kaibox-li', 'li' + kaiBox]"
                    v-for="(item, index) in imgList1"
                    :key="index"
                  >
                    <ul>
                      <li
                        v-for="(item1, index1) in item"
                        :key="index1"
                        :style="{
                          backgroundImage: 'url(' + item1.background + ')',
                        }"
                        :data="item1.name"
                      >
                        <img
                          :src="item1.img"
                          :class="{
                            'kaibox-img2': kaiBox == 2,
                          }"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <img class="dibu" src="../assets/img/dibu.png" alt="" />
            </div>
          </div>
          <!-- 3个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
          <div
            :class="[showNav == false ? 'kai-box-open3m' : 'kai-box-open3']"
            v-if="openBoxState && kaiBox == 3"
          >
            <div class="kai-warp1">
              <img class="dingbu" src="../assets/img/dibu.png" alt="" />
              <img class="kaibox-line" src="../assets/img/shuxian.png" alt="" />
              <div
                :class="{
                  'kaibox-warp kaibox-warp3': kaiBox == 3,
                }"
              >
                <ul class="kaibox-ul">
                  <li
                    class="kaibox-li"
                    v-for="(item, index) in imgList1"
                    :key="index"
                    :style="{
                      animation: 'run-li' + 1 + ' 7.5s',
                    }"
                  >
                    <ul>
                      <li
                        v-for="(item1, index1) in item"
                        :key="index1"
                        :style="{
                          backgroundImage: 'url(' + item1.background + ')',
                        }"
                        :data="item1.name"
                      >
                        <img
                          :src="item1.img"
                          :class="{
                            'kaibox-img3': kaiBox == 3,
                          }"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <img class="dibu" src="../assets/img/dibu.png" alt="" />
            </div>
          </div>
          <!-- 4个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
          <div
            :class="[showNav == false ? 'kai-box-open4m' : 'kai-box-open4']"
            v-if="openBoxState && kaiBox == 4"
          >
            <div class="kai-warp1">
              <img class="dingbu" src="../assets/img/dibu.png" alt="" />
              <img class="kaibox-line" src="../assets/img/shuxian.png" alt="" />
              <div
                :class="{
                  'kaibox-warp kaibox-warp4': kaiBox == 4,
                }"
              >
                <ul class="kaibox-ul">
                  <li
                    class="kaibox-li"
                    v-for="(item, index) in imgList1"
                    :key="index"
                    :style="{
                      animation: 'run-li' + 1 + ' 7.5s',
                    }"
                  >
                    <ul>
                      <li
                        v-for="(item1, index1) in item"
                        :key="index1"
                        :style="{
                          backgroundImage: 'url(' + item1.background + ')',
                        }"
                        :data="item1.name"
                      >
                        <img
                          :src="item1.img"
                          :class="{
                            'kaibox-img4': kaiBox == 4,
                          }"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <img class="dibu" src="../assets/img/dibu.png" alt="" />
            </div>
          </div>
          <!-- 5个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
          <div
            :class="[showNav == false ? 'kai-box-open5m' : 'kai-box-open5']"
            v-if="openBoxState && kaiBox == 5"
          >
            <div class="kai-warp1">
              <img class="dingbu" src="../assets/img/dibu.png" alt="" />
              <img class="kaibox-line" src="../assets/img/shuxian.png" alt="" />
              <div
                :class="{
                  'kaibox-warp kaibox-warp5': kaiBox == 5,
                }"
              >
                <ul class="kaibox-ul">
                  <li
                    class="kaibox-li"
                    v-for="(item, index) in imgList1"
                    :key="index"
                    :style="{
                      animation: 'run-li' + 1 + ' 7.5s',
                    }"
                  >
                    <ul>
                      <li
                        v-for="(item1, index1) in item"
                        :key="index1"
                        :style="{
                          backgroundImage: 'url(' + item1.background + ')',
                        }"
                        :data="item1.name"
                      >
                        <img
                          :src="item1.img"
                          :class="{
                            'kaibox-img5': kaiBox == 5,
                          }"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <img class="dibu" src="../assets/img/dibu.png" alt="" />
            </div>
          </div>
        </div>

        <!--中奖完成后盒子-->
        <div class="win-box" v-if="winState">
          <mywin
            :winList="winList"
            :winState="winState"
            @winexchange="winexchange($event)"
            @winget="winget($event)"
            @winX="winX($event)"
          ></mywin>
        </div>
        <div class="clear"></div>
        <!-- 箱子信息加载loading效果 -->
        <div
          v-show="loadFlag"
          v-loading="loadFlag"
          element-loading-text="拼命加载中"
          class="kai-masked"
        ></div>
        <!-- 箱子主体信息 -->
        <div v-show="box_name" class="kai-con" v-if="showNav">
          <div class="con-name">{{ box_name }}</div>
          <div class="con-list">
            <ul>
              <li v-for="item in kaiBox1" :key="item">
                <div class="conlist-warp">
                  <div class="conlist-box">
                    <img src="@/assets/new/lottery_bg3.png" />
                  </div>
                  <div class="conlist-box1">
                    <img :src="box_obj.img_active" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="con-num" v-if="!isNewUser">
            <ul>
              <li
                v-for="(item, index) in kaiBoxNum"
                :key="index"
                :class="item.state ? 'con-num-check' : ''"
                @click="chooseNum(item.num)"
              >
                <span>{{ item.num }}</span>
              </li>
            </ul>
          </div>

          <div class="buy-container">
            <div class="con-btn" @click="buyBox()">
              <div class="con-btn1" v-if="!isNewUser">
                <img src="../assets/img/money.png" /><span>
                  {{ (price * kaiBox).toFixed(2) }}
                </span>
              </div>
              <div class="con-btn1" v-else>新用户免费开箱</div>
            </div>
            <img
              :src="
                require(`@/assets/img/open-box/${!cartState}-animation.png`)
              "
              class="img"
              alt=""
              @click="cartState = !cartState"
              srcset=""
            />
          </div>
        </div>
        <!-- 箱子主体信息 -->
        <div class="kai-conm" v-else style="margin-top: 0px">
          <div class="moblie-box-name">{{ box_name }}</div>
          <div class="con-list" style="margin-top: 145px">
            <ul>
              <li v-for="item in kaiBox1" :key="item">
                <div class="conlist-warp">
                  <div class="conlist-box">
                    <img src="@/assets/new/lottery_bg3.png" />
                  </div>
                  <div class="conlist-box1">
                    <img :src="box_obj.img_active" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="con-num" v-if="!isNewUser">
            <ul>
              <li
                v-for="(item, index) in kaiBoxNum"
                :key="index"
                :class="item.state ? 'con-num-check' : ''"
                @click="chooseNum(item.num)"
              >
                <span>{{ item.num }}</span>
              </li>
            </ul>
          </div>

          <div class="buy-container">
            <div class="con-btn" @click="buyBox()">
              <div class="con-btn1" v-if="!isNewUser">
                <img src="../assets/img/money.png" /><span>
                  {{ (price * kaiBox).toFixed(2) }}
                </span>
              </div>
              <div class="con-btn1" v-else>新用户免费开箱</div>
            </div>
            <img
              :src="
                require(`@/assets/img/open-box/${!cartState}-animation.png`)
              "
              class="img"
              alt=""
              @click="cartState = !cartState"
              srcset=""
            />
          </div>
        </div>
        <div class="kai-num">
          <span></span>
        </div>
      </div>

      <div class="box-list">
        <div class="boxlist-top">
          <div class="left">
            <span :class="winBoxState ? '' : 'span1'" @click="getBoxOrn"
              >包含以下皮肤</span
            >
            <span :class="winBoxState ? 'span1' : ''" @click="getWinPeo"
              >最近掉落</span
            >
          </div>
        </div>
        <div class="boxlist-bot" v-if="!winBoxState && showNav">
          <ul>
            <li
              v-for="(item, index) in skin_list"
              :key="index"
              class="card"
              :style="{
                backgroundImage: 'url(' + item.background + ')',
              }"
            >
              <div class="boxlist-warp">
                <div class="boxlist1-top">
                  <span class="ico">{{ item.exteriorName }}</span>
                  <img :src="item.img" />
                </div>
                <div class="boxlist1-top-desc">
                  <div class="list-pirce">
                    <div class="pirce-left">
                      <img src="../assets/new/money.png" />
                      <div>
                        <span>{{ item.price }}</span>
                        <!-- <span>{{ item.probability }}%</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="boxlist1-bot" :title="item.name">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="boxlist-botm" v-if="!winBoxState && !showNav">
          <div class="increase-rose" v-if="skins_types.length">
            <div
              class="rose-item"
              v-for="(item, index) in skins_types"
              :key="index"
            >
              <div
                class="point"
                :style="{
                  background: item.color,
                }"
              ></div>
              <span :style="{ color: item.color }"
                >{{ item.skins_type_probability }}%</span
              >
            </div>
          </div>
          <ul>
            <li
              v-for="(item, index) in skin_list"
              :key="index"
              class="card"
              :style="{
                backgroundImage: 'url(' + item.background + ')',
              }"
            >
              <div class="boxlist-warp">
                <div class="boxlist1-top">
                  <span class="ico">{{ item.exteriorName }}</span>
                  <img :src="item.img" />
                </div>

                <div class="boxlist1-top-desc">
                  <div class="list-pirce">
                    <div class="pirce-left">
                      <img src="../assets/new/money.png" />
                      <div>
                        <span>{{ item.price }}</span>
                        <!-- <span>{{ item.probability }}%</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="boxlist1-bot" :title="item.name">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="clear"></div>

        <div class="boxlist-bot" v-if="winBoxState && showNav">
          <div v-if="winBoxState" class="filter-user-type">
            <span
              :class="{ active_type: active_type == 0 }"
              @click="
                active_type = 0;
                getWinPeo();
              "
            >
              会员</span
            >
            <span
              :class="{ active_type: active_type == 1 }"
              @click="
                active_type = 1;
                getWinPeo();
              "
              >主播</span
            >
          </div>
          <div class="fliter-tab">
            <p>用户名</p>
            <p>获得饰品详情</p>
          </div>
          <div
            class="drop-item"
            v-for="(item, index) in skin_list1"
            :key="index"
          >
            <div class="user-info">
              <img :src="item.player_img" />
              <span>{{ item.player_name }}</span>
              <img v-if="item.image" class="border-image" :src="item.image" />
            </div>
            <img :src="item.imageUrl" style="width: 80px; margin-right: 15px" />
            <p :style="{ color: item.color }">
              {{ item.skin_name }}
            </p>
          </div>
        </div>
        <div class="boxlist-botm" v-if="winBoxState && !showNav">
          <div v-if="winBoxState" class="filter-user-type">
            <span
              :class="{ active_type: active_type == 0 }"
              @click="
                active_type = 0;
                getWinPeo();
              "
            >
              会员</span
            >
            <span
              :class="{ active_type: active_type == 1 }"
              @click="
                active_type = 1;
                getWinPeo();
              "
              >主播</span
            >
          </div>
          <div class="fliter-tab">
            <p>用户名</p>
            <p>获得饰品详情</p>
          </div>
          <div
            class="drop-item"
            v-for="(item, index) in skin_list1"
            :key="index"
          >
            <span class="user">
              <img :src="item.player_img" />
              <span>{{ item.player_name }}</span>
              <img v-if="item.image" class="border-image" :src="item.image" />
            </span>
            <img :src="item.imageUrl" style="width: 80px; margin-right: 15px" />
            <span :style="{ color: item.color, width: '200px' }">{{
              item.skin_name
            }}</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>

      <!-- 分享-->
      <el-dialog
        title="分享链接"
        :visible.sync="dialogFormVisible"
        width="200px"
        class="share-hide"
      >
        <div class="share-btn">
          <el-input v-model="url" autocomplete="off"></el-input>
          <el-button class="btn" type="warning" @click="copyUrl()"
            >复制</el-button
          >
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- open_box2 -->
      <audio controls ref="notify" class="audio" style="display: none">
        <!-- <source src="../assets/audio/box3-9-11.mp3" /> -->
        <source src="../assets/audio/open_box_scroll.7134f.mp3" />
      </audio>

      <audio controls ref="notify1" class="audio" style="display: none">
        <source src="../assets/audio/14131.mp3" />
      </audio>
    </div>
  </div>
</template>

<script>
import myslide from "@/components/my_slide_page.vue";
import mywin from "@/components/my_win.vue";
import Utils from "../assets/js/util.js";
export default {
  components: {
    myslide,
    mywin,
  },
  computed: {
    isNewUser() {
      return this.$route.query.box_id == "62";
    },
  },
  props: ["showNav"],
  data() {
    return {
      openPhone: true,
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      cartState: false,
      skin_list1: [],
      winBoxState: false,
      loading: false,
      url: window.location.href,
      dialogFormVisible: false,
      funState: true,
      winState: false,
      openBoxState: false,
      box_id: this.$route.query.box_id,
      box_name: "",
      box_obj: {},
      price: 0,
      totalPrice: 0,
      skin_list: [],
      skins_types: [],
      value: true,
      kaiBox: 1,
      kaiBox1: 1,
      kaiBoxNum: [
        {
          num: 1,
          state: true,
        },
        {
          num: 2,
          state: false,
        },
        {
          num: 3,
          state: false,
        },
        {
          num: 4,
          state: false,
        },
        {
          num: 5,
          state: false,
        },
      ],
      winList: [],
      listBox: [],
      imgList: [],
      imgList1: [],
      loadFlag: true, // 页面加载Loading标识

      active_type: 0,
    };
  },
  watch: {
    kaiBox(val) {
      let _this = this;
      _this.totalPrice = (this.price * val).toFixed(2);
    },
    skin_list: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.caculateCardHeight();
          });
        }
      },
      deep: true,
    },
    skin_list1: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.caculateCardHeight();
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    refreshMoney() {
      this.$parent.selfLogin();
    },
    caculateCardHeight() {
      const els = document.querySelectorAll(".card");
      if (els[0]) {
        const width = window.getComputedStyle(els[0]).getPropertyValue("width");
        els.forEach((el) => {
          el.style.height = (238 / 154) * width.split("px")[0] + "px";
        });
      }
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    //音乐 过程
    playAlarm() {
      this.$refs.notify.currentTime = 0;
      this.$refs.notify.play();
    },
    //音乐 过程关闭
    playAlarmStop() {
      this.$refs.notify.pause();
    },
    //音乐 结果
    playAlarm1() {
      this.$refs.notify1.play();
    },
    //音乐 结果暂停
    playAlarm2() {
      this.$refs.notify1.pause();
    },

    //点击包含以下皮肤
    getBoxOrn() {
      this.winBoxState = false;
      //this.getBoxInfo();
      let _this = this;
      let param = {
        box_id: _this.box_id,
      };
      _this.$axios
        .post("/index/Box/boxInfo", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data) {
            _this.skin_list = res.data.data.box_skins;
          }
        });
    },
    //最近掉落
    getWinPeo() {
      this.winBoxState = true;
      let param = {
        page: 1,
        pageSize: 20,
        box_id: this.box_id,
        type: this.active_type,
      };
      this.$axios
        .post("/index/Box/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.skin_list1 = data.data.list;
          }
        });
    },
    //分享复制
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = window.location.href; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      this.dialogFormVisible = false;
    },
    //盲盒信息
    getBoxInfo(id) {
      let _this = this;
      let param = {
        box_id: id ? id : _this.box_id,
      };
      _this.$axios
        .post("/index/Box/boxInfo", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data) {
            //  console.log(res.data);
            this.loadFlag = false;
            this.box_obj = res.data.data;
            _this.box_name = res.data.data.name;
            _this.price = _this.totalPrice = res.data.data.price;
            _this.skin_list = res.data.data.box_skins;
            _this.skins_types = res.data.data.skins_types;
          }
        });
    },
    //购买盲盒
    buyBox() {
      this.loading = true;
      let _this = this;
      let param = {
        box_id: _this.$route.query.box_id,
        num: _this.kaiBox,

        cartState: this.cartState,
      };
      _this.$axios
        .post("/index/Box/buyBox", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data.status == 1) {
            //console.log(res.data);
            this.$store.commit(
              "getMoney",
              Number(res.data.data.total_amount).toFixed(2)
            );
            console.log(res.data.data.total_amount, "钱");
            Utils.$emit("money", res.data.data.total_amount);
            //let player_box_skin_id = res.data.data.player_box_skin_id;
            let imgCopy = JSON.parse(JSON.stringify(this.skin_list));
            if (!this.cartState) {
              this.playAlarm(); // 播放音乐
            }
            if (this.kaiBox == 1) {
              let imgCopy1 = [];
              for (let i = 0; i < Math.floor(80 / imgCopy.length) + 1; i++) {
                for (let j = 0; j < imgCopy.length; j++) {
                  imgCopy1.push(imgCopy[j]);
                }
              }
              imgCopy1 = imgCopy1.slice(0, 80);
              imgCopy1 = this.getRandomArr(imgCopy1, 80);
              this.imgList = imgCopy1;
              // console.log(this.imgList.length);
            } else if (this.kaiBox == 2) {
              let imgCopy2 = [];
              for (let i = 0; i < this.kaiBox; i++) {
                this.imgList1.push(imgCopy);
              }
              for (
                let i = 0;
                i < Math.floor(100 / this.imgList1[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.imgList1[0].length; j++) {
                  imgCopy2.push(this.imgList1[0][j]);
                }
              }
              imgCopy2 = imgCopy2.slice(0, 100);
              for (let i = 0; i < this.imgList1.length; i++) {
                this.imgList1[i] = imgCopy2;
                this.imgList1[i] = this.getRandomArr(this.imgList1[i], 100);
              }
            } else {
              let imgCopy2 = [];
              for (let i = 0; i < this.kaiBox; i++) {
                this.imgList1.push(imgCopy);
              }
              for (
                let i = 0;
                i < Math.floor(150 / this.imgList1[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.imgList1[0].length; j++) {
                  imgCopy2.push(this.imgList1[0][j]);
                }
              }
              imgCopy2 = imgCopy2.slice(0, 150);
              for (let i = 0; i < this.imgList1.length; i++) {
                this.imgList1[i] = imgCopy2;
                this.imgList1[i] = this.getRandomArr(this.imgList1[i], 150);
              }
            }

            //开盲盒
            //_this.getBoxResult(player_box_skin_id);
            this.loading = false;
            if (res.data.data.skins_info.length == 1) {
              this.imgList[62] = res.data.data.skins_info[0];
              if (!this.showNav) {
                this.imgList[60] = res.data.data.skins_info[0];
              }
              _this.setStyle(1);
            } else if (res.data.data.skins_info.length == 2) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][42] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][85] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(2);
            } else if (res.data.data.skins_info.length == 3) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][34] = data[i];
                if (!this.showNav) {
                  for (let z = 0; z < 81; z++) {
                    imgListCopy[i][80] = data[i];
                  }
                }
              }
              this.imgList1 = imgListCopy;
              var length = res.data.data.skins_info.length;
              _this.setStyle(3, length);
            } else if (res.data.data.skins_info.length == 4) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));
              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][41] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][89] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(4, length);
            } else if (res.data.data.skins_info.length == 5) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][54] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][123] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(5, length);
            }
            _this.winList = res.data.data;

            //是否开启动画
            if (this.cartState) {
              _this.winState = true;
              // this.playAlarmStop(); // 关闭音乐
              this.playAlarm1();
              // this.playAlarm();
            } else {
              _this.openBoxState = true;
              var length = res.data.data.skins_info.length;
              _this.setStyle("", length);
              setTimeout(() => {
                _this.openBoxState = false;
                _this.winState = true;
                this.imgList = [];
                this.imgList1 = [];
                // this.playAlarm(); 开启动画音乐
                // this.playAlarmStop(); // 关闭音乐
                // this.playAlarm1(); // 钱币音效
              }, 8000 + 200 * length);
              setTimeout(() => {
                this.playAlarmStop(); // 关闭音乐
              }, 6600 + 200 * length);
            }
          } else {
            this.loading = false;
            let msg = res.data.msg;
            if (msg.indexOf("当前余额不足") != -1) {
              this.$message({
                message: msg,
                type: "warning",
              });
              return;
            }
            if (msg == "新用户仅限于新用户开箱/萌新开箱") {
              this.$message({
                message: msg,
                type: "warning",
              });
              return;
            }
            if (msg == "新注册用户仅限开箱一次") {
              this.$message({
                message: msg,
                type: "warning",
              });
              return;
            }
            if (msg == "当天仅限开箱一次") {
              this.$message({
                message: msg,
                type: "warning",
              });
              return;
            }
            if (msg == "玩家信息错误") {
              this.$message({
                message: "请先登录",
                type: "warning",
              });
              this.$store.commit("getLogin", true);
              return;
            }
            if (msg == "购买失败，请联系客服") {
              this.$message("购买失败，请联系客服处理");
            }
          }
        });
    },

    //开盒规则
    goOpenboxRule() {
      this.$router.push({
        path: `/OpenboxRule`,
      });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    chooseNum(num) {
      // 默认只为一个 不做修改了
      this.kaiBox = num;

      for (let i = 0; i < this.kaiBoxNum.length; i++) {
        if (this.kaiBoxNum[i].num <= num) {
          this.kaiBoxNum[i].state = true;
        } else {
          this.kaiBoxNum[i].state = false;
        }
      }
    },
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg;
    },
    winget(msg) {
      this.winState = msg;
    },
    winX(msg) {
      this.winState = msg;
    },
    setStyle(index, num) {
      let style = document.createElement("style");
      style.setAttribute("type", "text/css");
      document.head.appendChild(style);
      let sheet = style.sheet;
      console.log(index);
      if (index == 1) {
        // let random = Math.floor(Math.random() * 190) + 11905;
        let random = 12000;
        // console.log(random);
        sheet.insertRule(
          `@keyframes run` +
            index +
            `{
							0% {
							  left: 0;
							}
							100% {
							  left: -` +
            random +
            `px
							}
						}`,
          0
        );
      } else if (index == 2) {
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 4800;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
							  0% {
								left: 0;
							  }
							  100% {
								left: -` +
              random +
              `px
							  }
						  }`,
            0
          );
        }
      } else if (index == 3) {
        console.log(num);
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 12000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else if (index == 4) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else if (index == 5) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      }
    },
  },
  mounted() {
    //判断屏幕宽度
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 600) {
      this.openPhone = false;
    }

    let _this = this;
    _this.getBoxInfo();
    _this.getBack();
    // _this.setStyle(5,5)

    //计算卡片高度
    window.addEventListener("resize", () => {
      this.caculateCardHeight();
    });
    let param = {
      page: 1,
      pageSize: 20,
      type: this.active_type,
      box_id: this.box_id,
    };
    this.$axios
      .post("/index/Box/lately", this.$qs.stringify(param))
      .then((res) => {
        let data = res.data;
        // console.log(data);
        if (data.status == 1) {
          this.skin_list1 = data.data.list;
        }
      });
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-switch__label--left {
    margin-right: 20px;
    color: #fff !important;
  }

  .el-switch__core {
    background-color: #00888a;
    border: 1px solid #00888a;
    height: 14px;

    &:after {
      height: 28px;
      width: 28px;
      top: -8px;
      left: -10px;
      background-color: #09ebaf;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: #00888a;
    background-color: #00888a;
  }
}

@media screen and (max-width: 375px) {
  .boxlist-botm ul {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)) !important;
  }
}

.border-image {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px !important;
  height: 60px !important;
  transform: translate(-30px, -30px);
}
.filter-user-type {
  display: flex;
  border: 3px solid #ffc400;
  width: max-content;
  margin-top: 20px;
  span {
    display: block;
    padding: 10px 40px;
    color: #fff;
    border: 3px solid #ffc400;
    font-size: 20px;
    cursor: pointer;
  }
  .active_type {
    background-color: #ffc400;
    color: #000000;
    font-weight: bold;
  }
}
.fliter-tab {
  display: flex;
  padding: 20px 20px 20px 35px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  p {
    color: #fff;
    font-size: 1.6vw;
    width: 50%;
  }
  p:nth-child(1) {
    color: #fff;
  }
}
.drop-item {
  display: flex;
  // justify-content: space-between;
  background-color: #181c28;
  align-content: center;
  padding: 20px;
  width: 50%;
  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 3px;
  }
  span:nth-child(2) {
    color: #fff;
  }
  span {
    color: #ffc400;
    font-size: 20px;
    // padding-left: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 20vw;
    display: flex;
  }
  p {
    color: #fff;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 50%;
  }
  .user-info {
    display: flex;
    position: relative;
    width: 50%;
    span {
      color: #fff;
      padding-left: 2px;
    }
  }
}
.drop-item:nth-child(2n) {
  background-color: #20222a;
}
.bax_num_bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.mySlide {
  padding-top: 19vw;
}
.openbox-page {
  background: url("../assets/95/header2.png") no-repeat;
  width: 100%;
  overflow: hidden;
  background-size: 100%;
  border-bottom: 0.16vw solid #ffc400;
}

.openbox {
  width: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  padding-bottom: 35px;
  /* 兼容Opera */
  zoom: 1;
  overflow: hidden;
  overflow-y: auto;
  // background: url("../assets/new/openbox2.png");
  //background: url("../assets/img/open-box/f685fe85d816fc917a7ff3ec0574c42.png");
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  // background-color: #000;
  // .mySlide{
  // 	margin-top: 25px;
  // }
  .yidong{
    background: url("../assets/img/open-box/f685fe85d816fc917a7ff3ec0574c42.png");
  }
  .switch-name {
    color: #848492;
    font-size: 16px;
    margin-right: 5px;
  }

  .switch2 {
    margin-right: 10px;
  }

  .audio {
    display: none;
  }

  .kai-box {
    position: relative;
    padding: 16px;
    // background-image: url("../assets/new/bg.png");
    // background-size: 600px auto;
    // background-repeat: no-repeat;
    // background-position: center 100px;
    .kai-share {
      float: right;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
      padding: 9px 22px;
      background-color: #333542;
      border-radius: 5px;
    }
    .kai-share:hover {
      background-color: #3a3f50;
      cursor: pointer;
    }
  }

  //测试开盒子 单个
  .kai-box-open2 {
    position: absolute;
    padding-bottom: 500px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    z-index: 106;

    .kai-warp11 {
      position: absolute;
      width: 1000px;
      height: 150px;
      top: 50%;
      left: 50%;
      margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        position: absolute;
        width: 8px;
        height: 210px;
        left: 50%;
        top: -30px;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 50%;
        left: 25%;
        top: -80px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        bottom: -80px;
        z-index: 99;
      }

      ul {
        position: absolute;
        left: 0;
        top: 0;
        width: 16000px;
        display: flex;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run4 8s;
        animation-timing-function: cubic-bezier(
          0.4,
          0.89,
          0.63,
          1
        ); //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          width: 200px;
          height: 150px;
          background-image: url("../assets/img/box-skins-null.jpg");
          background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }

        .li1 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .li2 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }
      }

      @keyframes run4 {
        0% {
          left: 0;
        }

        100% {
          left: -12000px;
        }
      }
    }
  }

  //测试开盒子 单个
  .kai-box-open2m {
    position: absolute;
    padding-bottom: 550px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    z-index: 106;

    .kai-warp11 {
      position: absolute;
      width: 1000px;
      height: 150px;
      top: 50%;
      left: 38%;
      margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        position: absolute;
        width: 8px;
        height: 100px;
        left: 54.4%;
        top: -10px;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 38.6%;
        left: 35%;
        top: -60px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 38.6%;
        left: 35%;
        bottom: 20px;
        transform: rotate(180deg);
      }

      ul {
        position: absolute;
        top: 0;
        width: 16000px;
        display: flex;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run44 8s;
        animation-timing-function: cubic-bezier(
          0.4,
          0.89,
          0.63,
          1
        ); //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          width: 100px;
          height: 75px;
          background-image: url("../assets/img/box-skins-null.jpg");
          background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }

        .li1 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .li2 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }
      }

      @keyframes run44 {
        0% {
          left: 0;
        }

        100% {
          left: -5500px;
        }
      }
    }
  }

  .kai-box-open22 {
    position: absolute;
    padding-bottom: 500px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    z-index: 66;

    .kai-warp11 {
      position: absolute;
      width: 1000px;
      height: 150px;
      top: 50%;
      left: 50%;
      margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        position: absolute;
        width: 8px;
        height: 360px;
        left: 50%;
        top: -180px;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 50%;
        left: 25%;
        top: -220px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        bottom: -60px;
        z-index: 99;
      }

      .kaibox-warp2 {
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            margin-top: -150px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.95,
              0.76,
              1
            ) !important; //动画慢 快 慢
            // animation-delay: 0.25s !important;
          }
        }
      }

      .kaibox-ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        .kaibox-li {
          //overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          animation: run5 8s;
          animation-iteration-count: 1 !important; //播放几次动画
          animation-delay: 0s; //动画运行前等待时间
          animation-fill-mode: forwards !important; //动画结束 是否保持

          // animation-timing-function: ease;
          // animation-iteration-count: 1;
          // animation-fill-mode: forwards;
          ul {
            width: 20000px;
            display: flex;
            flex-wrap: nowrap;
            z-index: 20;

            li {
              width: 200px;
              height: 150px;
              background-image: url("../assets/img/box-skins-null.jpg");
              background-size: 100% 100%;

              // border: 1px solid #fff;
              img {
                margin-left: 2%;
                margin-top: 10px;
                width: 95%;
                max-height: 180px;
              }
            }

            .li1 {
              background-image: url("../assets/img/box-skins-golden.jpg");
            }

            .li2 {
              background-image: url("../assets/img/box-skins-violet.jpg");
            }
          }
        }
      }

      @keyframes run5 {
        0% {
          left: 0;
        }

        100% {
          left: -8000px;
        }
      }
    }
  }

  .kai-box-open22m {
    position: absolute;
    padding-bottom: 500px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.88);
    z-index: 106;

    .kai-warp11 {
      position: absolute;
      width: 1000px;
      height: 150px;
      top: 50%;
      left: 50%;
      margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        position: absolute;
        width: 8px;
        height: 180px;
        left: 50%;
        top: -90px;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 50%;
        left: 25%;
        top: -140px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        bottom: 10px;
        left: 7px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp2 {
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            margin-top: -75px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.95,
              0.76,
              1
            ) !important; //动画慢 快 慢
            // animation-delay: 0.25s !important;
          }
        }
      }

      .kaibox-ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        .kaibox-li {
          //overflow: hidden;
          margin-left: -45px;
          position: absolute;
          left: 0;
          top: 0;
          animation: run5 8s;
          animation-iteration-count: 1 !important; //播放几次动画
          animation-delay: 0s; //动画运行前等待时间
          animation-fill-mode: forwards !important; //动画结束 是否保持

          // animation-timing-function: ease;
          // animation-iteration-count: 1;
          // animation-fill-mode: forwards;
          ul {
            width: 20000px;
            display: flex;
            flex-wrap: nowrap;
            z-index: 20;

            li {
              width: 100px;
              height: 75px;
              background-image: url("../assets/img/box-skins-null.jpg");
              background-size: 100% 100%;

              // border: 1px solid #fff;
              img {
                margin-left: 2%;
                width: 95%;
                max-height: 180px;
                margin-top: 5px !important;
              }
            }

            .li1 {
              background-image: url("../assets/img/box-skins-golden.jpg");
            }

            .li2 {
              background-image: url("../assets/img/box-skins-violet.jpg");
            }
          }
        }
      }

      @keyframes run5 {
        0% {
          left: 0;
        }

        100% {
          left: -8000px;
        }
      }
    }
  }

  //测试开多个盒子
  .kai-box-open3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 130px;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 860px;
        transform: rotate(270deg);
        left: 40%;
        top: -29%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -15px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -15px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 480px;
        padding-top: 60px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 290px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 240px;
                height: 180px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 240px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 480px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open3m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 350px;
      left: 50%;
      margin-left: -150px;
      margin-top: 130px;
      // overflow: hidden;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 340px;
        transform: rotate(270deg);
        left: 42%;
        top: -34%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 120%;
        left: -70px;
        top: -8px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 120%;
        left: -70px;
        bottom: -7px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 140px;
        padding-top: 62px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 290px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100px;
                height: 75px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 5px !important;
                  width: 95% !important;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 100px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 200px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 130px;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 860px;
        transform: rotate(270deg);
        left: 41.5%;
        top: -28%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -15px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -15px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 490px;
        padding-top: 50px;
        margin-left: -60px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 200px;
                height: 150px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp4 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 200px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 400px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 600px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open4m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 350px;
      left: 50%;
      margin-left: -110px;
      margin-top: 130px;
      // overflow: hidden;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 380px;
        transform: rotate(270deg);
        left: 31%;
        top: -50%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 120%;
        left: -100px;
        top: -7px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 120%;
        left: -100px;
        bottom: -7px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 142px;
        padding-top: 50px;
        margin-left: -65px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 90px;
                height: 67.5px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px !important;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp4 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 90px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 180px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 270px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 130px;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 860px;
        transform: rotate(270deg);
        left: 41.5%;
        top: -37.5%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -15px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -15px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 400px;
        padding-top: 102px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 150px;
                height: 112.5px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp5 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 150px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 300px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 450px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 600px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open5m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 106;

    .kai-warp1 {
      position: absolute;
      width: 350px;
      left: 50%;
      margin-left: -110px;
      margin-top: 130px;
      // overflow: hidden;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 5px;
        height: 370px;
        transform: rotate(270deg);
        left: 30.5%;
        top: -40%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -8px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -5px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 152px;
        padding-top: 50px;
        margin-left: -65px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 8s;
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 70px;
                height: 49px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 3px !important;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp5 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 70px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 140px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 210px;
            animation-delay: 0.45s !important;
          }
          .kaibox-li:nth-child(5) {
            left: 280px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }
  // .kai-box-open4 {
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: rgba(0, 0, 0, 0.5);
  // 	z-index: 66;

  // 	.kai-warp1 {
  // 		position: absolute;
  // 		width: 100%;
  // 		height: 120px;
  // 		top: 30%;
  // 		left: 0;
  // 		display: flex;
  // 		justify-content: center;

  // 		.kaibox-line {
  // 			width: 100%;
  // 			height: 2px;
  // 			position: absolute;
  // 			top: 59px;
  // 			left: 0;
  // 			z-index: 99;
  // 			background-color: #fff;
  // 		}

  // 		.kaibox-warp {
  // 			overflow: hidden;
  // 			width: 100%;

  // 			.kaibox-ul {
  // 				width: 100%;
  // 				display: flex;
  // 				flex-wrap: nowrap;
  // 				position: relative;

  // 				.kaibox-li {
  // 					//overflow: hidden;
  // 					width: 20%;
  // 					position: absolute;
  // 					top: 0;
  // 					left: 0;
  // 					animation: run6 10s;
  // 					animation-timing-function: cubic-bezier(0.4,
  // 							0.89,
  // 							0.63,
  // 							0.93) !important; //动画慢 快 慢
  // 					animation-iteration-count: 1 !important; //播放几次动画
  // 					animation-delay: 0s; //动画运行前等待时间
  // 					animation-fill-mode: forwards !important; //动画结束 是否保持

  // 					ul {
  // 						li {
  // 							width: 100%;
  // 							height: 120px;
  // 							background-image: url("../assets/img/box-skins-null.jpg");
  // 							background-size: 100% 100%;

  // 							// border: 1px solid #fff;
  // 							img {
  // 								// margin-left: 2%;
  // 								margin-top: 10px;
  // 								width: 95%;
  // 								max-height: 100px;
  // 							}
  // 						}

  // 						.li1 {
  // 							background-image: url("../assets/img/box-skins-golden.jpg");
  // 						}

  // 						.li2 {
  // 							background-image: url("../assets/img/box-skins-violet.jpg");
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp2 {

  // 			//width: 600px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 30%;
  // 					animation-delay: 0.25s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 49.5%;
  // 					animation-delay: 0.5s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp3 {

  // 			//width: 900px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 20%;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 39.5%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 59%;
  // 					animation-delay: 0.45s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp4 {

  // 			//width: 1200px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 10%;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 29.5%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 49%;
  // 					animation-delay: 0.45s !important;
  // 				}

  // 				.kaibox-li:nth-child(4) {
  // 					left: 68.5%;
  // 					animation-delay: 0.6s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp5 {

  // 			//width: 1500px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 0;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 19.9%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 39.8%;
  // 					animation-delay: 0.45s !important;
  // 				}

  // 				.kaibox-li:nth-child(4) {
  // 					left: 59.7%;
  // 					animation-delay: 0.6s !important;
  // 				}

  // 				.kaibox-li:nth-child(5) {
  // 					left: 79.6%;
  // 					animation-delay: 0.75s !important;
  // 				}
  // 			}
  // 		}

  // 		// @keyframes run6 {
  // 		//   0% {
  // 		//     top: 0;
  // 		//   }
  // 		//   100% {
  // 		//     //50
  // 		//     top: -6000px;
  // 		//   }
  // 		// }
  // 	}
  // }

  //中奖后盒子
  .win-box {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
  }

  .topic {
    width: 15%;
    min-width: 160px;
    margin-bottom: 50px;
  }

  .kai-masked {
    width: 100%;
    min-height: 600px;

    /deep/ .el-loading-mask {
      background-color: inherit !important;

      .circular {
        width: 64px;
        height: 64px;
      }

      .el-loading-spinner .path {
        stroke: #ff9b0b;
      }

      .el-loading-spinner .el-loading-text {
        color: #ff9b0b;
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }

  overflow: hidden;

  .kai-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    .con-name {
      margin-top: -40px !important;
      font-size: 0.8vw;
      font-weight: 700;
      background-color: #ffc400;
      color: #000;
      margin: 20px auto;
      width: 200px;
      height: 45px;
      line-height: 45px;
      text-align: center;
    }

    .con-list {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
          width: 100%;
          max-width: 600px;
          .conlist-warp {
            // border-radius: 50%;
            position: relative;
            // background-color: rgba(65, 105, 161, 0.2);
            // padding: 14% 0;
            margin: 0 5px;
            text-align: center;
            display: flex;
            justify-content: center;

            .conlist-box {
              height: auto;
              text-align: center;
              background: url("../assets/new/bg2.gif") no-repeat;
              background-position: center 0;
              background-size: 40%;
              img {
                width: 100%;
                height: 100%;
                visibility: hidden;
              }
            }

            .conlist-box1 {
              width: 60%;
              position: absolute;
              left: 0;
              top: 10px;
              left: 20%;
              animation: boxhover 1.5s linear 0s infinite alternate;

              img {
                width: 40%;
                height: 80%;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        li:hover .conlist-box1 {
          position: absolute;
          top: 10%;
          animation: boxhover 1.5s linear 0s infinite alternate;
        }

        @keyframes boxhover {
          0% {
            top: 50px;
          }

          50% {
            top: 100px;
          }

          100% {
            top: 50px;
          }
        }
      }
    }

    .con-num {
      margin-top: 10px;
      width: 100%;
      max-width: 600px;
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        height: 100%;
        li.con-num-check {
          background: #ffc400;
        }
        li {
          width: 50px;
          height: 50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border-radius: 50%;
          border-radius: 50%;
          background: #161c1a;
          border: 1px solid #ffc400;
          span {
            z-index: 88;
            font-size: 0.94vw;
            color: #fff;
            font-family: ninefont;
            font-weight: 600;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {
          // background-color: #ff9b0b;
          span {
            color: #000;
          }
        }
      }
    }

    .buy-container {
      margin-top: 20px;
      display: flex;
      align-items: center;
      position: relative;

      .img {
        cursor: pointer;
        height: 40px;
        position: absolute;
        top: 50%;
        right: calc(50% - 201px);
        transform: translate(-50%, -50%);
      }
    }

    .con-btn {
      display: flex;
      margin-right: 20px;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px;
      // border: 1px solid #fff;
      border: 1px solid #ffc400;

      // background-image:linear-gradient(to right,#87cde0, #c537bc);
      background-color: #ffc400;
      .con-btn1 {
        height: 45px;
        width: 201px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ninefont !important;
        cursor: pointer;

        span {
          font-size: 20px;
          color: #141221;
          font-family: ninefont !important;
          font-weight: 600;
          margin-left: 5px;
        }

        img {
          width: auto;
          height: 25px;
        }
      }

      .con-btn2 {
        border: none;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        color: white;
        // padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;
        margin-right: -1px;
      }

      .con-btn2:hover {
        cursor: pointer;
      }
    }
  }

  .kai-conm {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .moblie-box-name {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffc400;
      height: 33px;
      width: 161px;
      text-align: center;
      line-height: 33px;
      font-weight: 600;
    }

    .con-name {
      margin-top: 20px;
      font-size: 0.8vw;
      color: #000;
      width: 200px;
      height: 45px;
      line-height: 45px;
      text-align: center;
    }

    .con-list {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
          width: 100%;
          max-width: 600px;

          .conlist-warp {
            // border-radius: 50%;
            position: relative;
            // background-color: rgba(65, 105, 161, 0.2);
            // padding: 14% 0;
            margin: 0 5px;
            text-align: center;
            display: flex;
            justify-content: center;

            .conlist-box {
              height: auto;
              text-align: center;
              background: url("../assets/new/bg2.gif") no-repeat;
              background-position: center 0;
              background-size: 40%;
              img {
                width: 70%;
                height: 100%;
                visibility: hidden;
              }
            }

            .conlist-box1 {
              width: 60%;
              position: absolute;
              left: 0;
              top: 0px;
              left: 20%;
              animation: boxhover 1.5s linear 0s infinite alternate;

              img {
                width: 50%;
                height: 80%;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        li:hover .conlist-box1 {
          position: absolute;
          top: 10%;
          animation: boxhover 1.5s linear 0s infinite alternate;
        }

        @keyframes boxhover {
          0% {
            top: 50px;
          }

          50% {
            top: 100px;
          }

          100% {
            top: 50px;
          }
        }
      }
    }

    .con-num {
      margin-top: 10px;
      height: 70px;
      width: 100%;
      max-width: 600px;
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        height: 100%;
        li.con-num-check {
          background: #ffc400;
        }
        li {
          width: 60px;
          height: 60px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #161c1a;
          border: 1px solid #ffc400;
          span {
            z-index: 88;
            font-size: 28px;
            color: #fff;
            font-family: ninefont;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {
          // background-color: #ff9b0b;
          span {
            color: #000;
          }
        }
      }
    }

    .buy-container {
      margin-top: 40px;
      display: flex;
      align-items: center;
      position: relative;
      .img {
        cursor: pointer;
        height: 40px;
        position: absolute;
        top: 50%;
        right: calc(50% - 161px);
        transform: translate(-50%, -50%);
      }
    }

    .con-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px;
      // border: 1px solid #fff;
      border: 1px solid #ffc400;

      // background-image:linear-gradient(to right,#87cde0, #c537bc);
      background-color: #ffc400;
      .con-btn1 {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 162px;
        font-family: ninefont !important;
        height: 33px;

        span {
          font-size: 18px;
          color: #141221;
          font-weight: 600;
          font-family: ninefont !important;
          margin-left: 0px;
        }

        img {
          width: auto;
          height: 20px;
          margin-right: 10px;
        }
      }

      .con-btn2 {
        border: none;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        color: white;
        // padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;
        margin-right: -1px;
      }

      .con-btn2:hover {
        cursor: pointer;
      }
    }
  }

  .kai-num {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      color: #848492;
      display: flex;
      align-items: center;
    }

    i {
      color: #0e0d0d;
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
      color: #848492;
      font-weight: 200;
    }
  }

  .box-list {
    margin: 20px 16px 50px 16px;
    // padding: 0 16px 16px 16px;
    border-radius: 5px;

    .boxlist-top {
      padding: 0 16px 0 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 3px solid #ffc400;
      align-items: center;
      .left {
        span {
          font-size: 0.8vw;
          color: #fff;
          display: inline-block;
          padding: 10px 16px;
          border: 3px solid #ffc400;
          font-weight: 700;
          border-bottom: none;
        }

        .span1 {
          // border-bottom: 2px solid #f3ab34;
          // background-image: url("../assets/new/bg_border.png");
          background-color: #ffc400;
          color: #000;
        }

        span:hover {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .right-one,
        .right-two {
          display: flex;
        }

        .right-data {
          display: flex;
          align-items: center;

          .percent {
            margin-right: 15px;
            display: flex;
            align-items: center;

            span {
              display: table;
              width: 26px;
              height: 7px;
              border-radius: 5px;
            }

            .span1 {
              background-color: #0f0f0f;
            }

            .span11 {
              color: #0e0d0d;
            }

            .span2 {
              background-color: #b969d4;
            }

            .span12 {
              color: #b969d4;
            }

            .span3 {
              background-color: #adc8cd;
            }

            .span13 {
              color: #adc8cd;
            }
          }
        }
      }
    }

    .boxlist-bot {
      zoom: 0.64;
      padding: 10px 0px;
      // 电脑端使用flex
      ul {
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        // gap: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;

        li {
          width: 250px;
          background-size: 100% auto;
          background-repeat: no-repeat;
          cursor: pointer;
          margin-right: 15px;
          margin-bottom: 15px;

          .boxlist-warp {
            // background-color: #24252f;
            margin: 8px;
            border-radius: 5px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100%);

            .boxlist1-top {
              padding: 0 20px;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 100%;
                margin-top: 20px;
                height: auto;
                cursor: pointer;
              }
            }

            .boxlist1-bot {
              padding: 8px;
              color: #ffffff;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 18px;
              width: 150px;
              // word-wrap: break-word;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #fff;
                font-size: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .list-pirce {
              padding: 0px 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .ico {
              display: block;
              margin: 10px 0;
              font-size: 18px;
              color: #f4bd50;
              padding: 2px 4px;
            }

            .pirce-left {
              flex: 1;
              display: flex;
              align-items: center;
              padding-right: 10px;

              img {
                width: auto;
                height: 15px;
                margin-right: 5px;
              }

              & > div {
                flex: 1;
                color: #f4bd50;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:last-child {
                  color: #fff;
                }
              }
            }

            .pirce-right {
              color: #848492;
              font-size: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .boxlist-botm {
      zoom: 0.64;
      .increase-rose {
        height: 38px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        background-image: url("../assets/img/open-box/zjdl-bg.png");
        .rose-item {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .point {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
      ul {
        width: 100%;
        display: grid;
        grid-gap: 15px;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        li {
          width: 100%;
          background-size: 100% auto;
          background-repeat: no-repeat;
          cursor: pointer;

          .boxlist-warp {
            // background-color: #24252f;
            border-radius: 5px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .boxlist1-top {
              padding: 0 20px;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 100%;
                margin-top: 10%;
                height: auto;
                min-height: 100px;
                cursor: pointer;
              }
            }

            .boxlist1-bot {
              padding: 8px;
              width: 150px;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 18px;
              white-space: nowrap;
              // word-wrap: break-word;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #fff;
                font-size: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .list-pirce {
              padding: 0px 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .ico {
              display: block;
              margin: 20px 0 10px 0;
              font-size: 18px;
              color: #f4bd50;
              padding: 2px 4px;
            }

            .pirce-left {
              flex: 1;
              display: flex;
              align-items: center;
              padding-right: 10px;

              img {
                width: auto;
                height: 15px;
                margin-right: 5px;
              }

              & > div {
                flex: 1;
                color: #f4bd50;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:last-child {
                  color: #fff;
                }
              }
            }

            .pirce-right {
              color: #848492;
              font-size: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .boxlist1-top-desc {
    padding: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .share-hide {
    //height: 300px;
  }*/
  .share-btn {
    display: flex;

    .btn {
      margin-left: 10px;
    }
  }

  /deep/ .el-dialog__footer {
    display: none;
  }

  /deep/ .el-dialog__title {
    color: #848492;
  }

  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }

  /deep/ .el-dialog {
    min-height: none;
  }
}
@media screen and (max-width: 320px) {
  .openbox .box-list .boxlist-botm {
    padding: 0 !important;
  }
}
@media screen and (max-width: 366px) {
  .openbox .box-list .boxlist-botm {
    padding: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .drop-item span {
    font-size: 16px;
  }
  .boxlist1-top-desc {
    // margin-top: -15px;
    padding: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .openbox .box-list .boxlist-top .left span {
    font-size: 12px;
  }
  .openbox {
    // background-size: cover !important;
    // width: 166%;
    // transform: translate(-20%, 0);
    background-size: 240% 100%;
    background-position: center;
    .kai-box {
      padding: 0 !important;
      margin: 0;
    }

    .win-box {
      position: fixed;
      top: 0;
      left: 0 !important;
      height: 100vh;
      z-index: 1000;
      margin-left: 0 !important;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .kai-conm .con-name {
      display: none;
    }
    .kai-conm .buy-container {
      margin-top: 15px;
    }
    .kai-conm .con-num {
      height: auto;
      ul li {
        height: 45px;
        width: 45px;
        margin-right: 5px;
        span {
          font-size: 16px;
        }
      }
    }
  }
  .openbox.yidong {
    height: 420px;
    padding-bottom: 15px;
  }
  .con-name {
    margin-top: 20px;
    font-size: 0.8vw;
    font-weight: 700;
    margin-top: 60px !important;
    width: 200px;
    text-align: center;
    background-color: #ffc400;
    color: #000;
  }
  .openbox .box-list .boxlist-top {
    margin: 0;
  }
  .openbox .box-list .boxlist-botm {
    // padding: 0 20px;
    p {
      font-size: 16px;
    }
  }
  @keyframes boxhover {
    0% {
      top: 20px;
    }

    50% {
      top: 50px;
    }

    100% {
      top: 20px;
    }
  }
}
</style>
