<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <h5 @click="goopenrule">盲盒开箱</h5>
        >
        <h6>规则</h6>
      </div>

      <div class="rule-text">
        <div class="text-title">开箱规则</div>
        <p>
          1、
          新用户注册送的金币，仅限新用户开箱、萌新开箱，其他玩法需要充值后开放权限，提取无限制。
        </p>
        <p>
          2、
          开箱概率显示分为两种，一种是固定概率、另一种是动态概率<br>
          固定概率：概率数值固定不会随开箱而变化<br>
          动态概率：动态概率的数值，随着开箱次数和开奖结果而变动，看准时机入手会有意想不到的收获哦。
        </p>
        <p>
          3、
          新用户开箱、萌新开箱、小试牛刀概率为动态概率，其余的箱子概率为固定概率。
        </p>
      //</div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .rule-warp {
    padding: 16px;
  }
  .rule-text {
    margin-top: 20px;
    .text-title {
      padding: 10px 0;
      color: #c3c3e2;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #848492;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;

    h5 {
      font-size: 16px;
      font-weight: 200;
    }
    h5:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    h6 {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 16px;
    }
  }
}
</style>